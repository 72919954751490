import React from 'react';
import PropTypes from 'prop-types';

class FreezeWrapper extends React.Component {

  static propTypes = {
    shouldFreeze: PropTypes.bool
  };

  static defaultProps = {
    shouldFreeze: false
  };

  render() {
    return this.props.children;
  }
}

export default FreezeWrapper;
