import _get from 'lodash/get';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import BackgroundImage from '../../../common/components/BackgroundImage';
import SnsBlock from './SnsBlock';

class SnsContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    topText: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    const domain = _get(context, 'models.hosts.data.domain_url');
    this.state = {};
    this.snsAccounts = [
      {
        title: '日テレNEWS NNN',
        image: '/images/apple-touch-icon.png',
        props: {
          nnn: true,
        }
      },
      {
        title: 'NEWS ZERO',
        image: '/images/sns-zero.png',
        props: {
          zero: true,
        }
      },
      {
        title: 'news every.',
        image: '/images/sns-every.png',
        props: {
          every: true,
        }
      },
      {
        title: '深層報道 バンキシャ！',
        image: '/images/sns-bankisya.png',
        props: {
          bankisha: true,
        }
      },
      {
        title: 'Oha!4 NEWS LIVE',
        image: '/images/sns-oha4.png',
        props: {
          oha4: true,
        }
      },
      {
        title: '深層NEWS',
        image: '/images/sns-shinsou.png',
        props: {
          shinsou: true,
        }
      },
      {
        title: '日テレ政治部',
        image: '/images/ntvseijibu.jpeg',
        props: {
          seijibu: true,
        }
      },
      {
        title: '日テレスポーツ',
        image: '/images/ntv_sports_jp.jpeg',
        props: {
          sports: true,
        }
      },
      {
        title: '日テレNEWS・カルチャー',
        image: '/images/ntvnewsculture.jpeg',
        props: {
          culture: true,
        }
      },
      {
        title: 'Nippon TV News 24 Japan',
        image: '/images/N24English.png',
        props: {
          english: true,
        }
      },
      {
        title: 'そらジロー',
        image: '/images/sns-sorajirou.png',
        props: {
          sorajiro: true,
        }
      },
    ]
    const snsAccountImages = this.snsAccounts.map(e => domain + e.image);
    this.props.setPreloadImages(snsAccountImages);

    const snsImages = [
      '/images/x.png',
      '/images/svg/youtube.svg',
      '/images/svg/tiktok.svg',
      '/images/svg/line.svg',
      '/images/svg/facebook.svg',
      '/images/svg/instagram.svg'
    ];
    this.props.setPreloadImages(snsImages);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let snsBlock;

    const cards = _compact(
      _map(this.snsAccounts, item => {
        return (
          <div className="sns-item" key={`sns-item-${item.title}`}>
            <div className="sns-block">
              <BackgroundImage className="sns-img" url={item.image} altText={item.title} />
              <div className="sns-title">{item.title}</div>
            </div>
            <SnsBlock {...item.props} />
          </div>
        )
      })
    )

    snsBlock = (
      <div className="snsContent-content">
        <h2 className="category-main-title sns-main-title">SNSアカウント一覧</h2>
        <div className="sns-item-wrapper">
          {cards}
        </div>
      </div>
    );

    const topText = _get(this.context, 'topText');
    const canonical = `/sns`;
    const description = '日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」のSNSアカウント一覧です。'

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(`SNSアカウント一覧｜${topText}`) });
            metas.push({ property: 'og:type', content: 'article' });
            metas.push({ name: 'description', content: description });
            metas.push({ property: 'og:description', content: description });

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={`SNSアカウント一覧｜${topText}`} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        {snsBlock}
        <Analytics
          pageTitle={`SNSアカウント一覧｜${topText}`}
          path={canonical}
          env={_get(this.context, 'models.config.data.env')}
        />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(SnsContent);
export default root;
