import window from 'global/window';
import { compose, defaultProps, lifecycle, pure } from 'recompose';

const GoogleTagManager = compose(
  pure,
  defaultProps({ pageTitle: '' }),
  lifecycle({
    componentDidMount() {
      const dataLayer = window.dataLayer || [];
      let url = window.location.pathname.substring(1);
      if (window.location.search.length > 0) {
        url += window.location.search;
      }
      if (_.get(this.props, 'pageTitle')) {
        dataLayer.push({
          event: 'pageview',
          page: {
            url,
            title: this.props.pageTitle,
            path: `/${url.replace(/\?.+/, '')}`,
          },
        });
      }
    },
  }),
)(() => null);
export default GoogleTagManager;
