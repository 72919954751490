import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import _ from 'lodash';
import TaboolaWidget from '../../../common/components/TaboolaWidget';
import convertAdTagUiViewName from '../../../common/utils/convertAdTagUiViewName';

/**
 * Taboolaからのレコメンドを表示する
 */
class RecommendList extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    history: PropTypes.object,
    company: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};

    this.recommendListRef = React.createRef();
    this.articlePageList = [
      'articles',
      'ArticlesContentImages',
      'articleDetail',
      'ArticleDetailContentImages',
      'ArticleDetailContentImagesEachCompany'
    ];
    this.isGDPRCountry = _.get(context, 'models.config.data.isGDPRCountry', false);
    this.getYdnAd = this.getYdnAd.bind(this);
    this.getTaboolaBlock = this.getTaboolaBlock.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Ydn広告返却
  getYdnAd(isSpMode) {
    if (isSpMode) {
      return (
        <div className="publicity-contents wrapper-publicity" key="ydn-wrapper">
          <div id={`div-gpt-${convertAdTagUiViewName(this.context)}_sp_ydn_rec`} key="div-gpt-trenta_sp_ydn_rec"></div>
        </div>
      );
    } else {
      return (
        <div className="publicity-contents ydn-bnr-block" key="ydn-block">
          <div id={`div-gpt-${convertAdTagUiViewName(this.context)}_pc_ydn_rec`} key="div-gpt-trenta_pc_ydn_rec"></div>
        </div>
      );
    }
  }

  /**
   * Taboolaでのおすすめの間に挟まれている広告はTaboola側で広告タグを検知して広告を間に入れ込むことができる
   * Reactの記述ではTaboolaの末尾に広告を入れるだけで良い
   * TRENTA UI、各局UIともに記事詳細画面、画像詳細画面ではydn広告を表示する
   */
  getTaboolaBlock() {
    const uiView = _.get(this.context, 'routeHandler.uiView');
    const isShowYdnAd = _.includes(this.articlePageList, uiView);
    const addClassNames = _.get(this.props, 'spMode') ? '' : 'title-card-wrapper grid ad';
    const isSpMode = _.get(this.props, 'spMode') ? true : false;
    return (
      <React.Fragment>
        <div key={`taboola_${Date.now()}`} className={addClassNames}>
          <LazyLoadComponent>
            <TaboolaWidget isLazy={true} target="recommend" />
          </LazyLoadComponent>
        </div>
        {/* YDN広告 */}
        {isShowYdnAd ? this.getYdnAd(isSpMode) : null}
      </React.Fragment>
    );
  }

  render() {
    if (this.isGDPRCountry || _.get(this.props, 'invisibleAd', false)) {
      // Taboola利用画面・GDPR 対象国の場合、非表示
      // 広告が無効の場合も非表示
      console.warn('Your environment is not allowed to display advertisements because you are in a country subject to GDPR or because you are not permitted to display advertisements.');
      return null;
    }

    const recommendBlock = this.getTaboolaBlock();

    const spMainTitle = 'おすすめ';
    let spBlock = (
      <div key={`recommend-list${Date.now()}`} className="recommend-list a">
        <div ref={this.recommendListRef} key={`recommend-list-ref${Date.now()}`}>
          <div className="recommend-list-title">{spMainTitle}</div>
          <div className="title-card-wrapper">
            <div key={`recommend-ad${Date.now()}`}>{recommendBlock}</div>
          </div>
        </div>
      </div>
    );

    let pcBlock = (
      <div key={`recommend-list${Date.now()}`} className="recommend-list b">
        <div ref={this.recommendListRef} className="recommend-list-wrapper" key={`recommend-list-wrapper${Date.now()}`}>
          <div className="recommend-list-title">おすすめ記事</div>
          <div className="title-card-wrapper grid">
            <div key={`recommend-ad${Date.now()}`} className="asdf-1a">
              {recommendBlock}
            </div>
          </div>
        </div>
      </div>
    );
    if (this.props.company || this.props.trenta) {
      spBlock = (
        <div key={`recommend-list${Date.now()}`} className="recommend-list c">
          <div ref={this.recommendListRef} key={`recommend-list-ref${Date.now()}`}>
            <div className="recommend-list-title">{spMainTitle}</div>
            <div key={`recommend-ad${Date.now()}`}>{recommendBlock}</div>
          </div>
        </div>
      );
      pcBlock = (
        <div key={`recommend-list${Date.now()}`} className="recommend-list d">
          <div
            ref={this.recommendListRef}
            className="recommend-list-wrapper"
            key={`recommend-list-wrapper${Date.now()}`}
          >
            <div className="recommend-list-title">おすすめ</div>
            <div key={`recommend-ad${Date.now()}`}>{recommendBlock}</div>
          </div>
        </div>
      );
    }

    return this.props.spMode ? spBlock : pcBlock;
  }
}

export default withLayoutContext(RecommendList);
