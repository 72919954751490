// マップ情報
const localPrefectures = {
  all: {
    label: '全国',
  },
  hokkaido: {
    label: '北海道',
  },
  tohoku: {
    label: '東北',
    prefectures: [
      {
        title: '青森',
        label: 'aomori',
        link_url: '/nnn/tohoku/aomori',
      },
      {
        title: '岩手',
        label: 'iwate',
        link_url: '/nnn/tohoku/iwate',
      },
      {
        title: '秋田',
        label: 'akita',
        link_url: '/nnn/tohoku/akita',
      },
      {
        title: '宮城',
        label: 'miyagi',
        link_url: '/nnn/tohoku/miyagi',
      },
      {
        title: '山形',
        label: 'yamagata',
        link_url: '/nnn/tohoku/yamagata',
      },
      {
        title: '福島',
        label: 'fukushima',
        link_url: '/nnn/tohoku/fukushima',
      },
    ],
  },
  koshinetsu: {
    label: '甲信越',
    prefectures: [
      {
        title: '山梨',
        label: 'yamanashi',
        link_url: '/nnn/koshinetsu/yamanashi',
      },
      {
        title: '長野',
        label: 'nagano',
        link_url: '/nnn/koshinetsu/nagano',
      },
      {
        title: '新潟',
        label: 'niigata',
        link_url: '/nnn/koshinetsu/niigata',
      },
    ],
  },
  hokuriku: {
    label: '北陸',
    prefectures: [
      {
        title: '富山',
        label: 'toyama',
        link_url: '/nnn/hokuriku/toyama',
      },
      {
        title: '石川',
        label: 'ishikawa',
        link_url: '/nnn/hokuriku/ishikawa',
      },
      {
        title: '福井',
        label: 'fukui',
        link_url: '/nnn/hokuriku/fukui',
      },
    ],
  },
  tokai: {
    label: '東海',
    prefectures: [
      {
        title: '静岡',
        label: 'shizuoka',
        link_url: '/nnn/tokai/shizuoka',
      },
      {
        title: '愛知',
        label: 'aichi',
        link_url: '/nnn/tokai/aichi',
      },
      {
        title: '岐阜',
        label: 'gifu',
        link_url: '/nnn/tokai/gifu',
      },
      {
        title: '三重',
        label: 'mie',
        link_url: '/nnn/tokai/mie',
      },
    ],
  },
  kinki: {
    label: '近畿',
    prefectures: [
      {
        title: '大阪',
        label: 'osaka',
        link_url: '/nnn/kinki/osaka',
      },
      {
        title: '兵庫',
        label: 'hyogo',
        link_url: '/nnn/kinki/hyogo',
      },
      {
        title: '京都',
        label: 'kyoto',
        link_url: '/nnn/kinki/kyoto',
      },
      {
        title: '奈良',
        label: 'nara',
        link_url: '/nnn/kinki/nara',
      },
      {
        title: '滋賀',
        label: 'shiga',
        link_url: '/nnn/kinki/shiga',
      },
      {
        title: '和歌山',
        label: 'wakayama',
        link_url: '/nnn/kinki/wakayama',
      },
    ],
  },
  chugoku: {
    label: '中国',
    prefectures: [
      {
        title: '鳥取',
        label: 'tottori',
        link_url: '/nnn/chugoku/tottori',
      },
      {
        title: '島根',
        label: 'shimane',
        link_url: '/nnn/chugoku/shimane',
      },
      {
        title: '岡山',
        label: 'okayama',
        link_url: '/nnn/chugoku/okayama',
      },
      {
        title: '広島',
        label: 'hiroshima',
        link_url: '/nnn/chugoku/hiroshima',
      },
      {
        title: '山口',
        label: 'yamaguchi',
        link_url: '/nnn/chugoku/yamaguchi',
      },
    ],
  },
  shikoku: {
    label: '四国',
    prefectures: [
      {
        title: '香川',
        label: 'kagawa',
        link_url: '/nnn/shikoku/kagawa',
      },
      {
        title: '愛媛',
        label: 'ehime',
        link_url: '/nnn/shikoku/ehime',
      },
      {
        title: '徳島',
        label: 'tokushima',
        link_url: '/nnn/shikoku/tokushima',
      },
      {
        title: '高知',
        label: 'kochi',
        link_url: '/nnn/shikoku/kochi',
      },
    ],
  },
  kyushu: {
    label: '九州',
    prefectures: [
      {
        title: '福岡',
        label: 'fukuoka',
        link_url: '/nnn/kyushu/fukuoka',
      },
      {
        title: '佐賀',
        label: 'saga',
        link_url: '/nnn/kyushu/saga',
      },
      {
        title: '大分',
        label: 'oita',
        link_url: '/nnn/kyushu/oita',
      },
      {
        title: '長崎',
        label: 'nagasaki',
        link_url: '/nnn/kyushu/nagasaki',
      },
      {
        title: '熊本',
        label: 'kumamoto',
        link_url: '/nnn/kyushu/kumamoto',
      },
      {
        title: '宮崎',
        label: 'miyazaki',
        link_url: '/nnn/kyushu/miyazaki',
      },
      {
        title: '鹿児島',
        label: 'kagoshima',
        link_url: '/nnn/kyushu/kagoshima',
      },
      {
        title: '沖縄',
        label: 'okinawa',
        link_url: '/nnn/kyushu/okinawa',
      },
    ],
  },
};

// 放送局情報
const localBroadcaster = {
  hokkaido: {
    label: '北海道',
    broadcaster: [
      {
        title: '札幌テレビ',
        label: 'hokkaido',
        logo: '/images/provider_logos/logos/STV_96px32px.png',
        provider_id: 'stv',
      },
    ],
  },
  tohoku: {
    label: '東北',
    broadcaster: [
      {
        title: '青森放送',
        label: 'aomori',
        logo: '/images/provider_logos/logos/RAB_96px32px.png',
        provider_id: 'rab',
      },
      {
        title: 'テレビ岩手',
        label: 'iwate',
        logo: '/images/provider_logos/logos/TVI_96px32px.png',
        provider_id: 'tvi',
      },
      {
        title: 'ミヤギテレビ',
        label: 'miyagi',
        logo: '/images/provider_logos/logos/MMT_96px32px.png',
        provider_id: 'mmt',
      },
      {
        title: '秋田放送',
        label: 'akita',
        logo: '/images/provider_logos/logos/ABS_96px32px.png',
        provider_id: 'abs',
      },
      {
        title: '山形放送',
        label: 'yamagata',
        logo: '/images/provider_logos/logos/YBC_96px32px.png',
        provider_id: 'ybc',
      },
      {
        title: '福島中央テレビ',
        label: 'fukushima',
        logo: '/images/provider_logos/logos/FCT_96px32px.png',
        provider_id: 'fct',
      },
    ],
  },
  kanto: {
    label: '関東',
    broadcaster: [
      {
        title: '日本テレビ',
        label: 'kanto',
        logo: '/images/provider_logos/logos/NTV_96px32px.png',
        provider_id: 'ntv',
      }
    ],
  },
  koshinetsu: {
    label: '甲信越',
    broadcaster: [
      {
        title: 'テレビ新潟',
        label: 'niigata',
        logo: '/images/provider_logos/logos/TeNY_96px32px.png',
        provider_id: 'teny',
      },
      {
        title: 'テレビ信州',
        label: 'nagano',
        logo: '/images/provider_logos/logos/TSB_96px32px.png',
        provider_id: 'tsb',
      },
      {
        title: '山梨放送',
        label: 'yamanashi',
        logo: '/images/provider_logos/logos/YBS_96px32px.png',
        provider_id: 'ybs',
      },
    ],
  },
  hokuriku: {
    label: '北陸',
    broadcaster: [
      {
        title: '北日本放送',
        label: 'toyama',
        logo: '/images/provider_logos/logos/KNB_96px32px.png',
        provider_id: 'knb',
      },
      {
        title: 'テレビ金沢',
        label: 'ishikawa',
        logo: '/images/provider_logos/logos/KTK_96px32px.png',
        provider_id: 'ktk',
      },
      {
        title: '福井放送',
        label: 'fukui',
        logo: '/images/provider_logos/logos/FBC_96px32px.png',
        provider_id: 'fbc',
      },
    ],
  },
  tokai: {
    label: '東海',
    broadcaster: [
      {
        title: '静岡第一テレビ',
        label: 'shizuoka',
        logo: '/images/provider_logos/logos/SDT_96px32px.png',
        provider_id: 'sdt',
      },
      {
        title: '中京テレビ',
        label: 'aichi',
        logo: '/images/provider_logos/logos/CTV_96px32px.png',
        provider_id: 'ctv',
      },
    ],
  },
  kinki: {
    label: '近畿',
    broadcaster: [
      {
        title: '読売テレビ',
        label: 'niigata',
        logo: '/images/provider_logos/logos/YTV_96px32px.png',
        provider_id: 'ytv',
      },
    ],
  },
  chugoku: {
    label: '中国',
    broadcaster: [
      {
        title: '日本海テレビ',
        label: 'niigata',
        logo: '/images/provider_logos/logos/NKT_96px32px.png',
        provider_id: 'nkt',
      },
      {
        title: '広島テレビ',
        label: 'hiroshima',
        logo: '/images/provider_logos/logos/HTV_96px32px.png',
        provider_id: 'htv',
      },
      {
        title: '山口放送',
        label: 'yamaguchi',
        logo: '/images/provider_logos/logos/KRY_96px32px.png',
        provider_id: 'kry',
      },
    ],
  },
  shikoku: {
    label: '四国',
    broadcaster: [
      {
        title: '四国放送',
        label: 'shikoku',
        logo: '/images/provider_logos/logos/JRT_96px32px.png',
        provider_id: 'jrt',
      },
      {
        title: '西日本放送',
        label: 'nishinihon',
        logo: '/images/provider_logos/logos/RNC_96px32px.png',
        provider_id: 'rnc',
      },
      {
        title: '南海放送',
        label: 'niigata',
        logo: '/images/provider_logos/logos/RNB_96px32px.png',
        provider_id: 'rnb',
      },
      {
        title: '高知放送',
        label: 'kouchi',
        logo: '/images/provider_logos/logos/RKC_96px32px.png',
        provider_id: 'rkc',
      },
    ],
  },
  kyushu: {
    label: '九州',
    broadcaster: [
      {
        title: '福岡放送',
        label: 'fukuoka',
        logo: '/images/provider_logos/logos/FBS_96px32px.png',
        provider_id: 'fbs',
      },
      {
        title: '長崎国際テレビ',
        label: 'nagasaki',
        logo: '/images/provider_logos/logos/NIB_96px32px.png',
        provider_id: 'nib',
      },
      {
        title: '熊本県民テレビ',
        label: 'kumamoto',
        logo: '/images/provider_logos/logos/KKT_96px32px.png',
        provider_id: 'kkt',
      },
      {
        title: 'テレビ大分',
        label: 'ooita',
        logo: '/images/provider_logos/logos/TOS_96px32px.png',
        provider_id: 'tos',
      },
      {
        title: 'テレビ宮崎',
        label: 'miyazaki',
        logo: '/images/provider_logos/logos/UMK_96px32px.png',
        provider_id: 'umk',
      },
      {
        title: '鹿児島読売テレビ',
        label: 'kagoshima',
        logo: '/images/provider_logos/logos/KYT_96px32px.png',
        provider_id: 'kyt',
      },
    ],
  },
};

// 地図ボタン設定値
const localContentMapArray = [
  {
    name: '北海道',
    className: 'hokkaido'
  },
  {
    name: '東北',
    className: 'tohoku',
  },
  {
    name: '甲信越',
    className: 'koushinetsu',
  },
  {
    name: '関東',
    className: 'kanto',
  },
  {
    name: '北陸',
    className: 'hokuriku',
  },
  {
    name: '東海',
    className: 'tokai',
  },
  {
    name: '近畿',
    className: 'kinki',
  },
  {
    name: '中国',
    className: 'chugoku',
  },
  {
    name: '四国',
    className: 'shikoku',
  },
  {
    name: '九州',
    className: 'kyushu',
  },
]

export { localPrefectures, localBroadcaster, localContentMapArray };
