import window from 'global/window';
import _ from 'lodash';
import { compose, lifecycle } from 'recompose';
import adTagsDefine from '../../../models/adTagsDefine';

// タグ出力処理
const GoogleAdManager = compose(
  lifecycle({
    // GAMタグ 出しわけ
    getTags() {
      return this.spMode ? this.getTagsSP() : this.getTagsPC();
    },
    // タグマスタに渡すタグだし分けのためのコンテンツデータ
    getTagParams(articleData = {}) {
      const uiView = _.get(this.routeHandler, 'uiView');
      const companyCode = _.get(this.routeHandler, 'params.companyCode', ''); // 局ID取得
      const category = _.get(this.routeHandler, 'params.categoryCode', ''); // カテゴリー
      const tags = uiView === 'tag' ? [_.get(this.routeHandler, 'params.tagName', '')] : uiView === 'articleDetail' ? _.get(articleData, 'hashTag', []) : []; // ハッシュタグ(利用は記事詳細画面とタグ記事一覧のみ)
      return {
        nnn: companyCode,
        category: category,
        tags: tags,
      }
    },
    // SP GAM Tag ---->
    getTagsSP() {
      // 各タグ定義管理
      const articleData = this.articleData;
      const tags = adTagsDefine(this.getTagParams(articleData));
      const uiView = _.get(this.routeHandler, 'uiView');
      const isEachCompany = _.get(this.routeHandler, 'isEachCompany', false); // trueなら各局UI
      switch (uiView) {
        case 'home': // ヘッドライン(総合TOP)
          return [
            tags.news_top_sp_small_ad,
            tags.news_top_sp_2nd_rec,
            tags.news_top_sp_3rd_rec,
            tags.news_top_sp_4th_rec,
            tags.news_top_sp_5th_rec,
            tags.news_top_sp_overlay,
          ];
        case 'companyHome': // 各社TOP
          return [
            tags.news_nnn_top_sp_small_ad,
            tags.news_nnn_top_sp_2nd_rec,
            tags.news_nnn_top_sp_3rd_rec,
            tags.news_nnn_top_sp_4th_rec,
            tags.news_nnn_top_sp_5th_rec,
            tags.news_nnn_top_sp_overlay,
          ];
        case 'new':
          if (isEachCompany) {
            // 新着記事(NNN)
            return [
              tags.news_nnn_new_sp_5th_rec,
              tags.news_nnn_new_sp_overlay,
            ];
          } else {
            // 新着記事(NTV)
            return [
              tags.news_new_sp_5th_rec,
              tags.news_new_sp_overlay,
            ];
          }
        case 'articles':  // 記事詳細(カテゴリ無し)
        case 'articleDetail': // 記事詳細(TRENTA UI)
          if (isEachCompany) {
            // 記事詳細(NNN)
            return [
              tags.news_nnn_article_sp_1st_rec,
              tags.news_nnn_article_sp_5th_rec,
              tags.news_nnn_article_sp_ydn_rec,
              tags.news_nnn_article_sp_overlay,
            ];
          } else {
            // 記事詳細(NTV)
            return [
              tags.news_article_sp_1st_rec,
              tags.news_article_sp_5th_rec,
              tags.news_article_sp_ydn_rec,
              tags.news_article_sp_overlay,
            ];
          }
        case 'ArticlesContentImages': // 画像一覧
        case 'ArticleDetailContentImages': // 画像一覧(TRENTA UI)
          return [
            tags.news_image_sp_article_rec,
            tags.news_image_sp_5th_rec,
            tags.news_image_sp_ydn_rec,
            tags.news_image_sp_overlay,
          ];
        case 'ArticleDetailContentImagesEachCompany': // 画像一覧(各社UI)
          return [
            tags.news_nnn_image_sp_article_rec,
            tags.news_nnn_image_sp_5th_rec,
            tags.news_nnn_image_sp_ydn_rec,
            tags.news_nnn_image_sp_overlay,
          ];
        case 'special': // 特集
        case 'specialdetail': // 特集記事一覧
          if (isEachCompany) {
            // 特集(NNN)
            return [
              tags.news_nnn_feature_sp_5th_rec,
              tags.news_nnn_feature_sp_overlay,
            ];
          } else {
            // 特集(NTV)
            return [
              tags.news_feature_sp_5th_rec,
              tags.news_feature_sp_overlay,
            ];
          }
        case 'ranking': // ランキング
          if (isEachCompany) {
            // ranking(NNN)
            return [
              tags.news_nnn_ranking_sp_5th_rec,
              tags.news_nnn_ranking_sp_overlay,
            ];
          } else {
            // ranking(NTV)
            return [
              tags.news_ranking_sp_5th_rec,
              tags.news_ranking_sp_overlay,
            ];
          }
        case 'search': // 検索結果(NTV)
          return [
            tags.news_search_sp_5th_rec,
            tags.news_search_sp_overlay,
          ];
        case 'local': // 地方局一覧(NTV)
          return [
            tags.news_area_sp_5th_rec,
            tags.news_area_sp_overlay,
          ];
        case 'tag': // タグ記事一覧（NTV）
          return [
            tags.news_tag_sp_5th_rec,
            tags.news_tag_sp_overlay,
          ];
        case 'live': // ライブ(NTV)
          return [
            tags.news_live_sp_5th_rec,
            tags.news_live_sp_overlay,
          ];
        default: // その他
          return [
            tags.news_other_sp_5th_rec,
            tags.news_other_sp_overlay,
          ];
      }
    },
    // PC GAM Tag ---->
    getTagsPC() {
      // 各タグ定義管理
      const articleData = this.articleData;
      const tags = adTagsDefine(this.getTagParams(articleData));
      const uiView = _.get(this.routeHandler, 'uiView');
      const isEachCompany = _.get(this.routeHandler, 'isEachCompany', false); //trueなら各局UI
      switch (uiView) {
        case 'home':
          return [
            tags.news_top_pc_3rd_rec,
            tags.news_top_pc_4th_rec,
            tags.news_top_pc_5th_rec,
            tags.news_top_pc_6th_rec,
            tags.news_top_pc_bigrec,
            tags.news_top_pc_overlay,
          ];
        case 'companyHome':
          return [
            tags.news_nnn_top_pc_3rd_rec,
            tags.news_nnn_top_pc_4th_rec,
            tags.news_nnn_top_pc_5th_rec,
            tags.news_nnn_top_pc_6th_rec,
            tags.news_nnn_top_pc_bigrec,
            tags.news_nnn_top_pc_overlay,
          ];
        case 'new':
          if (isEachCompany) {
            //新着記事(NNN)
            return [
              tags.news_nnn_new_pc_bnr,
              tags.news_nnn_new_pc_3rd_rec,
              tags.news_nnn_new_pc_4th_rec,
              tags.news_nnn_new_pc_5th_rec,
              tags.news_nnn_new_pc_6th_rec,
              tags.news_nnn_new_pc_bigrec,
              tags.news_nnn_new_pc_overlay,
            ];
          } else {
            //新着記事(NTV)
            return [
              tags.news_new_pc_bnr,
              tags.news_new_pc_3rd_rec,
              tags.news_new_pc_4th_rec,
              tags.news_new_pc_5th_rec,
              tags.news_new_pc_6th_rec,
              tags.news_new_pc_bigrec,
              tags.news_new_pc_overlay,
            ];
          }
        case 'articles':
        case 'articleDetail':
          if (isEachCompany) {
            //記事詳細(NNN)
            return [
              tags.news_nnn_article_pc_bnr,
              tags.news_nnn_article_pc_1st_rec,
              tags.news_nnn_article_pc_2nd_rec,
              tags.news_nnn_article_pc_ydn_rec,
              tags.news_nnn_article_pc_5th_rec,
              tags.news_nnn_article_pc_6th_rec,
              tags.news_nnn_article_pc_bigrec,
              tags.news_nnn_article_pc_overlay,
            ];
          } else {
            //記事詳細(NTV)
            return [
              tags.news_article_pc_bnr,
              tags.news_article_pc_1st_rec,
              tags.news_article_pc_2nd_rec,
              tags.news_article_pc_ydn_rec,
              tags.news_article_pc_5th_rec,
              tags.news_article_pc_6th_rec,
              tags.news_article_pc_bigrec,
              tags.news_article_pc_overlay,
            ];
          }
        case 'ArticlesContentImages':
        case 'ArticleDetailContentImages':
          return [
            tags.news_image_pc_bnr,
            tags.news_image_pc_1st_rec,
            tags.news_image_pc_2nd_rec,
            tags.news_image_pc_ydn_rec,
            tags.news_image_pc_5th_rec,
            tags.news_image_pc_6th_rec,
            tags.news_image_pc_bigrec,
            tags.news_image_pc_overlay,
          ];
        case 'ArticleDetailContentImagesEachCompany':
          return [
            tags.news_nnn_image_pc_bnr,
            tags.news_nnn_image_pc_1st_rec,
            tags.news_nnn_image_pc_2nd_rec,
            tags.news_nnn_image_pc_ydn_rec,
            tags.news_nnn_image_pc_5th_rec,
            tags.news_nnn_image_pc_6th_rec,
            tags.news_nnn_image_pc_bigrec,
            tags.news_nnn_image_pc_overlay,
          ];
        case 'special': // 特集
        case 'specialdetail': // 特集記事一覧
          if (isEachCompany) {
            // 特集(NNN)
            return [
              tags.news_nnn_feature_pc_bnr,
              tags.news_nnn_feature_pc_3rd_rec,
              tags.news_nnn_feature_pc_4th_rec,
              tags.news_nnn_feature_pc_5th_rec,
              tags.news_nnn_feature_pc_6th_rec,
              tags.news_nnn_feature_pc_bigrec,
              tags.news_nnn_feature_pc_overlay,
            ];
          } else {
            // 特集(NTV)
            return [
              tags.news_feature_pc_bnr,
              tags.news_feature_pc_3rd_rec,
              tags.news_feature_pc_4th_rec,
              tags.news_feature_pc_5th_rec,
              tags.news_feature_pc_6th_rec,
              tags.news_feature_pc_bigrec,
              tags.news_feature_pc_overlay,
            ];
          }
        case 'ranking': // ランキング
          if (isEachCompany) {
            // ranking(NNN)
            return [
              tags.news_nnn_ranking_pc_bnr,
              tags.news_nnn_ranking_pc_3rd_rec,
              tags.news_nnn_ranking_pc_4th_rec,
              tags.news_nnn_ranking_pc_5th_rec,
              tags.news_nnn_ranking_pc_6th_rec,
              tags.news_nnn_ranking_pc_bigrec,
              tags.news_nnn_ranking_pc_overlay,
            ];
          } else {
            // ranking(NTV)
            return [
              tags.news_ranking_pc_bnr,
              tags.news_ranking_pc_3rd_rec,
              tags.news_ranking_pc_4th_rec,
              tags.news_ranking_pc_5th_rec,
              tags.news_ranking_pc_6th_rec,
              tags.news_ranking_pc_bigrec,
              tags.news_ranking_pc_overlay,
            ];
          }
        case 'search': // 検索結果(NTV)
          return [
            tags.news_search_pc_bnr,
            tags.news_search_pc_3rd_rec,
            tags.news_search_pc_4th_rec,
            tags.news_search_pc_5th_rec,
            tags.news_search_pc_6th_rec,
            tags.news_search_pc_bigrec,
            tags.news_search_pc_overlay,
          ];
        case 'local': // 地方局一覧(NTV)
          return [
            tags.news_area_pc_bnr,
            tags.news_area_pc_3rd_rec,
            tags.news_area_pc_4th_rec,
            tags.news_area_pc_5th_rec,
            tags.news_area_pc_6th_rec,
            tags.news_area_pc_bigrec,
            tags.news_area_pc_overlay,
          ];
        case 'tag': // タグ記事一覧（NTV）
          return [
            tags.news_tag_pc_bnr,
            tags.news_tag_pc_3rd_rec,
            tags.news_tag_pc_4th_rec,
            tags.news_tag_pc_5th_rec,
            tags.news_tag_pc_6th_rec,
            tags.news_tag_pc_bigrec,
            tags.news_tag_pc_overlay,
          ];
        case 'live': // ライブ(NTV)
          if (!isEachCompany) {
            return [
              tags.news_live_pc_bnr,
              tags.news_live_pc_3rd_rec,
              tags.news_live_pc_4th_rec,
              tags.news_live_pc_5th_rec,
              tags.news_live_pc_6th_rec,
              tags.news_live_pc_bigrec,
              tags.news_live_pc_overlay,
            ];
          }
        default: //その他
          return [
            tags.news_other_pc_bnr,
            tags.news_other_pc_3rd_rec,
            tags.news_other_pc_4th_rec,
            tags.news_other_pc_5th_rec,
            tags.news_other_pc_6th_rec,
            tags.news_other_pc_bigrec,
            tags.news_other_pc_overlay,
          ];
      }
    },
    componentDidMount() {
      this.spMode = _.get(this.props, 'spMode');
      this.routeHandler = _.get(this.props, 'routeHandler');
      this.invisibleAd = _.get(this.props, 'invisibleAd');
      this.articleData = _.get(this.props, 'articleData');

      console.log("GoogleAdManager this.articleData:", this.articleData);
      console.log("GoogleAdManager this.routeHandler:", this.routeHandler);

      const lines = this.getTags(this.spMode, this.routeHandler);

      googletag.cmd.push(function () {
        googletag.pubads().enableSingleRequest();
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
      });
      this.renderAdDidMount = true;
      console.log('renderAds');
      console.log('lines : ', lines);
      window.fluxtag.renderAds(lines, window.fluxtag.failSafeTimeout);
    },
    componentDidUpdate(prevProps, prevState) {
      // move from componentWillReceiveProps
      this.spMode = _.get(this.props, 'spMode');
      this.routeHandler = _.get(this.props, 'routeHandler');
      this.invisibleAd = _.get(this.props, 'invisibleAd');
      this.beforeSpMode = _.get(prevProps, 'spMode');
      this.beforeRouteHandler = _.get(prevProps, 'routeHandler');

      if (
        this.renderAdDidMount &&
        !this.invisibleAd &&
        (this.beforeSpMode !== this.spMode ||
          _.get(this.beforeRouteHandler, 'path') !== _.get(this.routeHandler, 'path'))
      ) {
        this.renderAdDidMount = false;
      }

      if (
        !this.renderAdDidMount &&
        (this.spMode !== this.beforeSpMode ||
          _.get(this.routeHandler, 'path') !== _.get(this.beforeRouteHandler, 'path'))
      ) {
        const lines = this.getTags(this.spMode, this.routeHandler);
        googletag.cmd.push(function () {
          googletag.pubads().enableSingleRequest();
          googletag.pubads().disableInitialLoad();
          googletag.enableServices();
        });
        console.log('renderAds');
        console.log('lines : ', lines);
        window.fluxtag.renderAds(lines, window.fluxtag.failSafeTimeout);
      }
    },
  }),
)(() => null);
export default GoogleAdManager;
