module.exports = {
  host: 'http://news.ntv.co.jp',
  ssr_host: 'https://news.ntv.co.jp',
  domain_url: 'https://news.ntv.co.jp',
  env: 'production',

  common_api_config: {
    catalog_id: 'n24_free',
    team_id: 'n24',
  },

  analytics: {
    gtm: {
      id: 'GTM-5G9JWFM',
    },
    cxense_config: {
      siteId: '1139742632080034260',
    },
  },

  services: {
    playback: {
      hostname: 'playback.api.streaks.jp',
    },
  },

  cookie: {
    name: 'n24-web-session',
    expires: 3153600000000,
  },
  session: {
    cookie: {
      path: '/',
      secure: false,
      httpOnly: true,
    },
  },
  service: {
    endpoint: 'https://news.ntv.co.jp',
    timeout: 3000,
  },
  tagGroups: {
    hashTag: 'hash',
    categoryTag: 'category',
    featureTag: 'feature',
  },
  categoryTags: {
    code2name: {
      "politics": "政治",
      "economy": "経済",
      "international": "国際",
      "society": "社会",
      "sports": "スポーツ",
      "culture": "カルチャー",
      "life": "ライフ",
    },
    genres: {
      '1a1d1484d3cbc721070469e555f6a0cc89c03d60181e1f13694014e9249d19b6': {
        code: 'politics',
        name: '政治',
      },
      '6fda2b8252b6d5ea9d19934d61b30d7a4a2922108fc1ecb83416001760139a39': {
        code: 'economy',
        name: '経済',
      },
      '1e3bb894756f8d62b5759cb1bbe8e52929921ee40a2b5f34147a99d823ad4faa': {
        code: 'international',
        name: '国際',
      },
      '0f5eae21697a35ca7a164a1db45a2ee14ecdaba3f04a661ae3f6cc2ab90eb4cb': {
        code: 'society',
        name: '社会',
      },
      '1cb542228c76558789d114d3cb273a75850cca54ec3ee9a41100f2dc56ee561e': {
        code: 'sports',
        name: 'スポーツ',
      },
      'f1947f79fdfb8046150959ca09cdd05cb53672ad4c0f49a87bbc7cddf5c91293': {
        code: 'culture',
        name: 'カルチャー',
      },
      '63bd7065ef1e165a04255d4048836ce126dfe2b6a0777722ad3a5c6013129d19': {
        code: 'life',
        name: 'ライフ',
      },
    },
    name2code: {
      "政治": "politics",
      "経済": "economy",
      "国際": "international",
      "社会": "society",
      "スポーツ": "sports",
      "カルチャー": "culture",
      "ライフ": "life",
    },
    // 時間単位
    rankingLimit: {
      all: 24,
      politics: 72,
      economy: 72,
      international: 72,
      society: 24,
      sports: 48,
      culture: 72,
      life: 168,
    },
  },
  notificationTypes: {
    ntv: '677772b73296483b97853273442c8dc8',
    htv: '10c55ae2e52f43f496cd0c4a1e599cb5',
    rnb: 'fc59d473912f4dc282653c3a6047ad79',
    fbs: 'f733f5ed2d4741f1ac1853531008ef29',
    sdt: 'b1327c93ff90407e8a110345e59f1b2c',
    kry: '8982e1ac61514838b524f73c6f0ee515',
    teny: '11a2daa5cea0475c88a35dab0cc05ee0',
    kkt: '7afa27885b834f55af4847949132ba8e',
    ybs: '3b538698e9e14c5eb3c9f03ec0ff26ff',
    rab: 'afb297b0e6af4655bac5b778572f17ab',
    mmt: '6f0423f156334ac2bd4c0794be0b5592',
    kyt: 'bf461b68a5df498f8bbf1080be74c3ca',
    ybc: '4b3d67c344ed49a9882800ed02967b6d',
    ctv: '641f433e5c9344f4a7ef6f12a3b6858f',
    ytv: 'fb9f7092014d4d4ba85eacd3269a694f',
    tsb: '75d706e311e843fe9041c66420a551c7',
    stv: '7d9abb0e1aa84b869fe75ddb39114282',
    abs: 'e3dca6456c31481798a424fad6cc8483',
    nkt: '031c3cf693034a04a85783b8031ae3df',
    fct: 'ad60321270d448a8b11e85a0629c6c0f',
    jrt: '741ee95df42942e3ac913569cfb3c42f',
    nib: '2a05d26af993496b8839be9f3e559d32',
    fbc: 'f015455d6487496cabbc34cc34f27aa9',
    rkc: 'c9b11349e9204369b35afda42788dcef',
    tvi: '5d99cf28884a4047bbf2a67b1a1dec02',
    rnc: '9fa261445ef94f35969332a583c93a49',
    tos: '702a381190854407b196e39bd0258abf',
    knb: '5f08ea86a6b948b1a1e671db42e45579',
    ktk: 'a2446e7e06e048c1bc32fdea483e375e',
    umk: 'bd5a6572b3ab4cc2b754e928d7ac4546',
  },
  staticJsonService: {
    endpoint: 'https://news.ntv.co.jp',
    timeout: 3000,
  },
  trentaStaticJsonService: {
    endpoint: 'https://news.ntv.co.jp',
    timeout: 3000,
  },

  tagService: {
    endpoint: `https://gallery.api.streaks.jp/teams/{project_ref_id}/tags`,
    contentType: 'application/json',
    timeout: 3000,
  },

  featureService: {
    endpoint: `https://gallery-appendix-front-api.typeline.play.jp/api/v1/project/{project_ref_id}/features`,
    contentType: 'application/json',
    timeout: 3000,
  },

  linkService: {
    endpoint: `https://gallery-appendix-front-api.typeline.play.jp/api/v1/project/{project_ref_id}/links`,
    api_key: '',
    contentType: 'application/json',
    timeout: 3000,
  },

  notificationService: {
    endpoint: `https://gallery-appendix-front-api.typeline.play.jp/api/v1/project/{project_ref_id}/notifications`,
    api_key: '',
    contentType: 'application/json',
    timeout: 3000,
  },

  // {project_ref_id}をTYPELINE(GALLERY)のプロジェクトIDで差し替える
  articleService: {
    endpoint: `https://gallery-appendix-front-api.typeline.play.jp/api/v1/project/{project_ref_id}/article`,
    api_key: '',
    contentType: 'application/json',
    timeout: 5000,
  },

  // 主に各局ライブでGALLERYからコンテンツを取得する際に使用
  catalogService: {
    endpoint: `https://gallery.api.streaks.jp`,
    contentType: 'application/json',
    timeout: 5000,
  },

  // 「一緒に見られているニュース」の記事をCxenseから取得する際に使用
  cxenseService: {
    endpoint: 'https://api.cxense.com/public/widget/data',
    timeout: 1000,
  },

  // JOCDN URL認証
  trentaService: {
    protocol: 'https',
    hostname: 'news.ntv.co.jp',
    timeout: 5000,
    path: ['api', 'generate-pta'],
  },

  validReturnToDomains: ['localhost'],
  instreamAD: {
    article: [
      {
        type: 'vmap',
        src: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/120937086/jp.co.ntv.news/article&description_url=[referrer_url]&url=[referrer_url]&env=vp&impl=s&correlator=[timestamp]&gdfp_req=1&output=vmap&sz=640x480&unviewed_position_start=1&ad_rule=1&vpmute=0&plcmt=1&wta=0&cust_params=news_category%3D[news_category]%26news_tag%3D[news_tag]%26news_video_dur%3D[news_video_dur]%26news_channel%3D[news_channel]',
      },
    ],
    live: [
      {
        type: 'vast',
        src: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/120937086/jp.co.ntv.news/live&description_url=[referrer_url]&url=[referrer_url]&env=vp&impl=s&correlator=[timestamp]&tfcd=0&npa=0&gdfp_req=1&output=vast&sz=640x480&unviewed_position_start=1&ad_rule=0&pmxd=60000&pmad=1&pmnd=0&vpos=preroll&cust_params=',
        time_offset: 'start',
      },
    ],
  },
  // prefixマッピング
  provider_prefix: {
    st: 'stv-news',
    ra: 'rab-news',
    tv: 'tvi-news',
    mm: 'mmt-news',
    ab: 'abs-news',
    yb: 'ybc-news',
    fc: 'fct-news',
    te: 'teny-news',
    ts: 'tsb-news',
    ys: 'ybs-news',
    kn: 'knb-news',
    kt: 'ktk-news',
    fb: 'fbc-news',
    sd: 'sdt-news',
    ct: 'ctv-news',
    yt: 'ytv-news',
    nk: 'nkt-news',
    ht: 'htv-news',
    kr: 'kry-news',
    jr: 'jrt-news',
    rn: 'rnc-news',
    rb: 'rnb-news',
    rc: 'rkc-news',
    fs: 'fbs-news',
    ni: 'nib-news',
    kk: 'kkt-news',
    to: 'tos-news',
    um: 'umk-news',
    ky: 'kyt-news'
  },
};
