import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withModalContext } from '../../../common/context/ModalContext';

class EachStation extends Component {
  static propTypes = {
    links: PropTypes.array,
  };

  static contextTypes = {
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.props.links || !this.props.links.length) {
      return null;
    }

    let links;
    links = _.compact(
      _.map(this.props.links || [], (linkData, index) => {
        if (!linkData || !linkData.title) return;
        return (
          <a key={`each-station-link-${index}`} href={linkData.link_url} className="list" rel="noopener noreferrer" target="_blank">
            {linkData.title}
          </a>
        );
      }),
    );

    return (
      <React.Fragment key="each-station">
        <div className="category-main-title">NNN各局のニュース</div>
        <div className="each-station">
          {links}
        </div>
      </React.Fragment>
    );
  }
}

export default withModalContext(EachStation);
