import React, { Component } from 'react';
import classnames from 'classnames';

const sleep = function(time) {
  return new Promise((resolve, reject) => setTimeout(() => resolve(), time));
};

class LoadingBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      width: 0,
      wait: false,
      full: false,
      progress: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.progress != state.progress) {
      return {
        progress: props.progress,
        width: props.progress,
      };
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.width == 100 && this.props.progress != prevProps.progress) {
      setTimeout(async () => {
        if (!this._isMounted) return;
        await sleep(700);
        if (!this._isMounted) return;
        this.setState({
          full: true,
        });
        await sleep(250);
        if (!this._isMounted) return;
        this.setState({
          show: false,
          width: 0,
        });
        setTimeout(() => {
          if (!this._isMounted) return;
          this.setState({
            show: true,
            full: false,
          });
        });
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { show, full, width } = this.state;
    if (!show) return null;
    return (
      <div className={classnames('loading-bar', { 'loading-bar--full': full })}>
        <div className="loading-bar-glow" style={{ width: `${width}%` }} />
      </div>
    );
  }
}

export default LoadingBar;
