import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withLayoutContext } from '../../common/context/LayoutContext';
import LoadingBar from './LoadingBar';

class PageTransitionProgressBar extends React.PureComponent {

  static propTypes = {
    path: PropTypes.string,
    history: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      prevPath: decodeURIComponent(props.routeHandler.path),
      progress: 0,
      url: this.props.routeHandler.url,
    };
    this.handleHistoryChange = this.handleHistoryChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.unlisten = this.props.history.listen(this.handleHistoryChange);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.routeHandler.url != state.url) {
      return {
        progress: 100,
        url: props.url,
      };
    }
    return null;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.unlisten) this.unlisten();
  }

  handleHistoryChange(location, action) {
    if (_.get(location, 'state.norender')) return;
    if (this._isMounted && this.state.prevPath != location.pathname) {
      if (this.props.overlayHeaderNavigation) {
        this.props.overlayHeaderNavigation(false);
      }
      if (this.props.changeLaunchAppPath) {
        this.props.changeLaunchAppPath();
      }
      this.setState({ prevPath: location.pathname }, this.loadingProgress);
    }
  }

  loadingProgress() {
    const updateProgress = progress => {
      if (this._isMounted) {
        this.setState({ progress: progress }, () => {
        });
      }
    };
    updateProgress(90);
  }

  render() {
    return <LoadingBar progress={this.state.progress} />;
  }
}

export default withLayoutContext(PageTransitionProgressBar);