import TitleCard from './TitleCard';
import React from 'react';
import PropTypes from 'prop-types';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import TaboolaWidget from '../../../common/components/TaboolaWidget';

/**
 * 「一緒に見られているニュース」モジュール
 * 先方共有済みドキュメント(Box)： https://app.box.com/file/1444026038099
 */
class SuggestArticleList extends React.Component {
  static contextTypes = {
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.renderSuggestArticlesSP = this.renderSuggestArticlesSP.bind(this);
    this.renderSuggestArticlesPC = this.renderSuggestArticlesPC.bind(this);
    this.renderRecommend = this.renderRecommend.bind(this);
  }

  renderRecommend(target) {
    if (target === 'below1') {
      return <TaboolaWidget target="below1" invisibleAd={_get(this.props, 'invisibleAd')} />;
    } else if (target === 'below2') {
      return <TaboolaWidget target="below2" invisibleAd={_get(this.props, 'invisibleAd')} />;
    }
    return null;
  }

  /**
   * SP版一緒に見られている記事
   * SPのみ記事枠の2,4枠目にレコメンド広告を表示する
   */
  renderSuggestArticlesSP(items) {
    const returnItems = [];

    if (!items || items.length === 0) {
      return [];
    }

    for (let i = 0; i < items.length + 2; i++) {
      if (i === 1) {
        returnItems.push(this.renderRecommend('below1'));
      } else if (i === 3) {
        returnItems.push(this.renderRecommend('below2'));
      } else {
        switch (i) {
          case 0:
            returnItems.push(
              <TitleCard
                key={`together_${0}`}
                suggestArticles={true}
                itemData={items[0]}
              />
            );
            break;
          case 2:
            returnItems.push(
              <TitleCard
                key={`together_${1}`}
                suggestArticles={true}
                itemData={items[1]}
              />
            );
            break;
          default:
            returnItems.push(
              <TitleCard
                key={`together_${i - 2}`}
                suggestArticles={true}
                itemData={items[i - 2]}
              />
            );
            break;
        }
      }
    }

    return returnItems;
  }

  renderSuggestArticlesPC(items) {
    return _compact(
      _map(items, (itemData, index) => {
        if (!itemData || !itemData.source_systems) return;
        return (
          <TitleCard
            key={`together_${index}`}
            suggestArticles={true}
            itemData={itemData}
          />
        )
      })
    );
  }

  render() {
    const items = _get(this.props, 'articles');
    const cards = _get(this.context, 'spMode', false) ? this.renderSuggestArticlesSP(items) : this.renderSuggestArticlesPC(items);

    if (!cards || _isEmpty(cards)) {
      return null;
    } else {
      return (
        <div className="suggestArticleList" key="ranking-list">
          <div className="suggestArticleList-title">
            <i className="fa-readed-together"></i>
            <span className="suggestArticleList-title-text">一緒に見られているニュース</span>
          </div>
          <div className="suggestArticleList-items">
            {cards}
          </div>
        </div>
      );
    }
  }
}
const root = withLayoutContext(SuggestArticleList);
export default root;
