import _ from 'lodash';

const broadcasters = {
  // 開発用_trenta08-newsの場合はSTVの情報を返す。
  trenta08: {
    title: 'stv',
    label: '札幌テレビ',
    area: '北海道',
    short_label: 'STV',
    nnn: 'STV NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/STV_96px32px.png',
      e_m: '/images/provider_logos/nnn/STV_NNN_200px40px.png',
    },
  },
  trenta06: {
    title: 'ctv',
    label: '中京テレビ',
    area: '愛知・岐阜・三重',
    short_label: '中京テレビ',
    nnn: '中京テレビNEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/CTV_96px32px.png',
      e_m: '/images/provider_logos/nnn/CTV_NNN_200px40px.png',
    },
  },
  trenta05: {
    title: 'ytv',
    label: '読売テレビ',
    area: '大阪・兵庫・京都・奈良・滋賀・和歌山',
    short_label: 'YTV',
    nnn: 'YTV NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/YTV_96px32px.png',
      e_m: '/images/provider_logos/nnn/YTV_NNN_200px40px.png',
    },
  },
  n24: {
    title: 'n24',
    label: '日本テレビ放送網',
    area: '',
    short_label: '',
    nnn: '日テレNEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/NTV_96px32px.png',
      e_m: '/images/provider_logos/nnn/NTV_NNN_200px40px.png',
    },
  },
  // TRENTA UI (NTV)「最新のニュース一覧」で利用
  ntv: {
    title: 'ntv',
    label: '日本テレビ',
    area: '',
    short_label: 'NTV',
    nnn: '日テレNEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/NTV_96px32px.png',
      e_m: '/images/provider_logos/nnn/NTV_NNN_200px40px.png',
    },
  },
  stv: {
    title: 'stv',
    label: '札幌テレビ',
    area: '北海道',
    short_label: 'STV',
    nnn: 'STV NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/STV_96px32px.png',
      e_m: '/images/provider_logos/nnn/STV_NNN_200px40px.png',
    },
  },
  rab: {
    title: 'rab',
    label: '青森放送',
    area: '青森',
    short_label: '青森放送',
    nnn: '青森放送NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/RAB_96px32px.png',
      e_m: '/images/provider_logos/nnn/RAB_NNN_200px40px.png',
    },
  },
  tvi: {
    title: 'tvi',
    label: 'テレビ岩手',
    area: '岩手',
    short_label: 'TVI',
    nnn: 'TVI NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/TVI_96px32px.png',
      e_m: '/images/provider_logos/nnn/TVI_NNN_200px40px.png',
    },
  },
  mmt: {
    title: 'mmt',
    label: 'ミヤギテレビ',
    area: '宮城',
    short_label: 'ミヤテレ',
    nnn: 'ミヤテレNEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/MMT_96px32px.png',
      e_m: '/images/provider_logos/nnn/MMT_NNN_200px40px.png',
    },
  },
  abs: {
    title: 'abs',
    label: '秋田放送',
    area: '秋田',
    short_label: 'ABS',
    nnn: 'ABS NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/ABS_96px32px.png',
      e_m: '/images/provider_logos/nnn/ABS_NNN_200px40px.png',
    },
  },
  ybc: {
    title: 'ybc',
    label: '山形放送',
    area: '山形',
    short_label: 'YBC',
    nnn: 'YBC NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/YBC_96px32px.png',
      e_m: '/images/provider_logos/nnn/YBC_NNN_200px40px.png',
    },
  },
  fct: {
    title: 'fct',
    label: '福島中央テレビ',
    area: '福島',
    short_label: '中テレ',
    nnn: '中テレNEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/FCT_96px32px.png',
      e_m: '/images/provider_logos/nnn/FCT_NNN_200px40px.png',
    },
  },
  teny: {
    title: 'teny',
    label: 'テレビ新潟',
    area: '新潟',
    short_label: 'TeNY',
    nnn: 'TeNY NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/TeNY_96px32px.png',
      e_m: '/images/provider_logos/nnn/TeNY_NNN_200px40px.png',
    },
  },
  tsb: {
    title: 'tsb',
    label: 'テレビ信州',
    area: '長野',
    short_label: 'テレビ信州',
    nnn: 'テレビ信州NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/TSB_96px32px.png',
      e_m: '/images/provider_logos/nnn/TSB_NNN_200px40px.png',
    },
  },
  ybs: {
    title: 'ybs',
    label: '山梨放送',
    area: '山梨',
    short_label: 'YBS',
    nnn: 'YBS NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/YBS_96px32px.png',
      e_m: '/images/provider_logos/nnn/YBS_NNN_200px40px.png',
    },
  },
  knb: {
    title: 'knb',
    label: '北日本放送',
    area: '富山',
    short_label: 'KNB',
    nnn: 'KNB NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/KNB_96px32px.png',
      e_m: '/images/provider_logos/nnn/KNB_NNN_200px40px.png',
    },
  },
  ktk: {
    title: 'ktk',
    label: 'テレビ金沢',
    area: '石川',
    short_label: 'テレ金',
    nnn: 'テレ金NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/KTK_96px32px.png',
      e_m: '/images/provider_logos/nnn/KTK_NNN_200px40px.png',
    },
  },
  fbc: {
    title: 'fbc',
    label: '福井放送',
    area: '福井',
    short_label: 'FBC',
    nnn: 'FBC NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/FBC_96px32px.png',
      e_m: '/images/provider_logos/nnn/FBC_NNN_200px40px.png',
    },
  },
  sdt: {
    title: 'sdt',
    label: '静岡第一テレビ',
    area: '静岡',
    short_label: 'Daiichi-TV',
    nnn: 'Daiichi-TV NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/SDT_96px32px.png',
      e_m: '/images/provider_logos/nnn/SDT_NNN_200px40px.png',
    },
  },
  ctv: {
    title: 'ctv',
    label: '中京テレビ',
    area: '愛知・岐阜・三重',
    short_label: '中京テレビ',
    nnn: '中京テレビNEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/CTV_96px32px.png',
      e_m: '/images/provider_logos/nnn/CTV_NNN_200px40px.png',
    },
  },
  ytv: {
    title: 'ytv',
    label: '読売テレビ',
    area: '大阪・兵庫・京都・奈良・滋賀・和歌山',
    short_label: 'YTV',
    nnn: 'YTV NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/YTV_96px32px.png',
      e_m: '/images/provider_logos/nnn/YTV_NNN_200px40px.png',
    },
  },
  nkt: {
    title: 'nkt',
    label: '日本海テレビ',
    area: '鳥取・島根',
    short_label: '日本海テレビ',
    nnn: '日本海テレビNEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/NKT_96px32px.png',
      e_m: '/images/provider_logos/nnn/NKT_NNN_200px40px.png',
    },
  },
  htv: {
    title: 'htv',
    label: '広島テレビ',
    area: '広島',
    short_label: '広テレ！',
    nnn: '広テレ！NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/HTV_96px32px.png',
      e_m: '/images/provider_logos/nnn/HTV_NNN_200px40px.png',
    },
  },
  kry: {
    title: 'kry',
    label: '山口放送',
    area: '山口',
    short_label: 'KRY',
    nnn: 'KRY NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/KRY_96px32px.png',
      e_m: '/images/provider_logos/nnn/KRY_NNN_200px40px.png',
    },
  },
  jrt: {
    title: 'jrt',
    label: '四国放送',
    area: '徳島',
    short_label: 'JRT',
    nnn: 'JRT NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/JRT_96px32px.png',
      e_m: '/images/provider_logos/nnn/JRT_NNN_200px40px.png',
    },
  },
  rnc: {
    title: 'rnc',
    label: '西日本放送',
    area: '香川・岡山',
    short_label: 'RNC',
    nnn: 'RNC NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/RNC_96px32px.png',
      e_m: '/images/provider_logos/nnn/RNC_NNN_200px40px.png',
    },
  },
  rnb: {
    title: 'rnb',
    label: '南海放送',
    area: '愛媛',
    short_label: '南海放送',
    nnn: '南海放送NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/RNB_96px32px.png',
      e_m: '/images/provider_logos/nnn/RNB_NNN_200px40px.png',
    },
  },
  rkc: {
    title: 'rkc',
    label: '高知放送',
    area: '高知',
    short_label: 'RKC',
    nnn: 'RKC NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/RKC_96px32px.png',
      e_m: '/images/provider_logos/nnn/RKC_NNN_200px40px.png',
    },
  },
  fbs: {
    title: 'fbs',
    label: '福岡放送',
    area: '福岡・佐賀',
    short_label: 'FBS',
    nnn: 'FBS NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/FBS_96px32px.png',
      e_m: '/images/provider_logos/nnn/FBS_NNN_200px40px.png',
    },
  },
  nib: {
    title: 'nib',
    label: '長崎国際テレビ',
    area: '長崎',
    short_label: 'NIB',
    nnn: 'NIB NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/NIB_96px32px.png',
      e_m: '/images/provider_logos/nnn/NIB_NNN_200px40px.png',
    },
  },
  kkt: {
    title: 'kkt',
    label: '熊本県民テレビ',
    area: '熊本',
    short_label: 'KKT',
    nnn: 'KKT NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/KKT_96px32px.png',
      e_m: '/images/provider_logos/nnn/KKT_NNN_200px40px.png',
    },
  },
  tos: {
    title: 'tos',
    label: 'テレビ大分',
    area: '大分',
    short_label: 'TOS',
    nnn: 'TOS NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/TOS_96px32px.png',
      e_m: '/images/provider_logos/nnn/TOS_NNN_200px40px.png',
    },
  },
  umk: {
    title: 'umk',
    label: 'テレビ宮崎',
    area: '宮崎',
    short_label: 'UMK',
    nnn: 'UMK NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/UMK_96px32px.png',
      e_m: '/images/provider_logos/nnn/UMK_NNN_200px40px.png',
    },
  },
  kyt: {
    title: 'kyt',
    label: '鹿児島読売テレビ',
    area: '鹿児島',
    short_label: 'KYT',
    nnn: 'KYT NEWS NNN',
    img_src: {
      l: '/images/provider_logos/logos/KYT_96px32px.png',
      e_m: '/images/provider_logos/nnn/KYT_NNN_200px40px.png',
    },
  },
};

export const getBroadcasterData = (id) => {
  if (_.isEmpty(id)) {
    return {
      title: id,
      label: '{companyName}',
      area: '{area}',
      short_label: '{short_label}',
      nnn: '{nnn}',
      img_src: {
        l: '/images/dummy/dummy_logo.png',
        e_m: '/images/dummy/dummy_logo.png',
      }
    }
  }
  let key = id;
  if (id.endsWith('-news')) {
    key = id.slice(0,-5);
  }
  return _.get(broadcasters, key, {
    title: key,
    label: '{companyName}',
    area: '{area}',
    short_label: '{short_label}',
    nnn: '{nnn}',
    img_src: {
      l: '/images/dummy/dummy_logo.png',
      e_m: '/images/dummy/dummy_logo.png',
    },
  });
};

export default getBroadcasterData;
