import hoistStatics from 'hoist-non-react-statics';
import invariant from 'invariant';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import HtmlContext from '../context/HtmlContext';

export default function htmlContext(WrappedComponent) {
  invariant(
    typeof WrappedComponent === 'function',
    `You must pass a component to the function returned by ` +
      `htmlContext. Instead received ${JSON.stringify(WrappedComponent)}`,
  );

  class HtmlContextHOC extends Component {
    static __meta__ = WrappedComponent.__meta__;

    static getPrefetchPaths = WrappedComponent.getPrefetchPaths;

    static contextTypes = {
      getModelData: PropTypes.func,
      getCookieDough: PropTypes.func,
      store: PropTypes.object,
      isStore: PropTypes.bool,
      routeHandler: PropTypes.object,
      models: PropTypes.object,
    };

    constructor(props, context) {
      super(props, context);
      const ogImageUrl = _.get(context, 'routeHandler.isEachCompany')
        ? `${_.get(context, 'models.hosts.data.host')}/images/og_images/og_images_${_.get(context, 'routeHandler.params.companyCode')}.png`
        : `${_.get(context, 'models.hosts.data.host')}/images/og_images.png`;

      this.defaultMetatags = {
        author: '日本テレビ',
        title: '日テレNEWS NNN',
        shortTitle: '日テレNEWS NNN',
        metaTag: '日テレNEWS NNN',
        keywords: '日テレNEWS NNN,日本テレビ',
        description: '日本テレビのニュースサイト「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。',
        ogTitle: '日テレNEWS NNN',
        ogDescription: '日本テレビのニュースサイト「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。',
        ogSiteName: '日テレNEWS NNN',
        ogUrl: `${_.get(context, 'models.hosts.data.ssr_host')}/`,
        ogImage: ogImageUrl,
        copyRight: '©Nippon Television Network Corporation',
      };

      this.metaTags = [
        { name: 'author', content: this.defaultMetatags.author },
        { name: 'keywords', content: this.defaultMetatags.keywords },
        { name: 'description', content: this.defaultMetatags.description },
        { name: 'Copyright', content: this.defaultMetatags.copyRight },
        { property: 'og:title', content: this.defaultMetatags.ogTitle },
        { property: 'og:type', content: 'article' },
        { property: 'og:site_name', content: this.defaultMetatags.ogSiteName },
        { property: 'og:url', content: this.defaultMetatags.ogUrl },
        { property: 'og:image', content: this.defaultMetatags.ogImage },
        { property: 'og:image:type', content: 'image/png' },
        { property: 'og:image:width', content: '630' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:description', content: this.defaultMetatags.ogDescription },
        { property: 'og:locale', content: 'ja_JP' },
        { property: 'fb:app_id', content: '1412827229019816' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@news24ntv' },
      ];

      if (_.get(context, 'models.config.data.env') === 'production') {
        this.metaTags.push({ property: 'fb:pages', content: '282571518441363' });
        this.metaTags.push({ property: 'fb:pages', content: '211790478849971' });
      }
    }

    componentDidMount() {
      this._isMounted = true;
    }

    componentDidUpdate(beforeProps) {}

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const path = _.get(this.context, 'routeHandler.url');
      const host = this.context.getModelData('hosts', 'ssr_host');
      if (path) {
        let item = _.find(this.metaTags, { property: 'og:url' });
        item.content = host + path;
      }

      return (
        <React.Fragment>
          <Helmet
            defaultTitle={this.defaultMetatags.title}
            // titleTemplate={`%s | ${this.defaultMetatags.metaTag}`}
            titleTemplate={`%s`}
            meta={this.metaTags}
            script={[
              {
                type: 'application/ld+json',
                innerHTML: JSON.stringify({
                  '@context': 'http://schema.org',
                  '@type': 'Organization',
                  '@id': 'https://news.ntv.co.jp/',
                  'url': 'https://news.ntv.co.jp/pages/about',
                  'name': '日テレNEWS NNN',
                  'logo': {
                    '@type': 'ImageObject',
                    'url': 'https://news.ntv.co.jp/images/logo.svg'
                  },
                }),
              },
              {
                type: 'application/ld+json',
                innerHTML: JSON.stringify({
                  '@context': 'http://schema.org',
                  '@type': 'Organization',
                  '@id': 'https://www.ntv.co.jp/',
                  'name': '日本テレビ放送網株式会社',
                  'url': 'https://www.ntv.co.jp/info/outline/outline.html',
                  'image': 'https://www.ntv.co.jp/assets/images/logo/ntv_black.svg'
                }),
              },
              {
                type: 'application/ld+json',
                innerHTML: JSON.stringify({
                  '@context':'http://schema.org',
                  '@type':'WebSite',
                  'name':'日テレNEWS NNN',
                  'alternateName':'日テレNEWS NNN',
                  'url':'https://news.ntv.co.jp/',
                  'potentialAction': {
                    '@type':'SearchAction',
                    'target':'https://news.ntv.co.jp/search?q={search_term}',
                    'query-input':'required name=search_term'
                  },
                }),
              },
            ]}
          ></Helmet>
          <HtmlContext.Provider
            value={{
              // shortTitle: (val) => `${val} | ${this.defaultMetatags.shortTitle}`,
              shortTitle: (val) => `${val}`,
              keywords: (val) => (val ? `${val},${this.defaultMetatags.keywords}` : this.defaultMetatags.keywords),
              description: (val) => `${val}`,
            }}
          >
            {React.createElement(WrappedComponent, this.props)}
          </HtmlContext.Provider>
        </React.Fragment>
      );
    }
  }

  HtmlContextHOC.displayName = 'HtmlContext';

  return hoistStatics(HtmlContextHOC, WrappedComponent);
}
