import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withModalContext } from '../../../common/context/ModalContext';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';

class ScrollMenu extends Component {
  static propTypes = {
    headerMenu: PropTypes.bool,
    localMenu: PropTypes.bool,
    rankingMenu: PropTypes.bool,
    categoryTag: PropTypes.bool,
  };

  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    getModelData: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.hoverReset = this.hoverReset.bind(this);
    this.handleMenuActive = this.handleMenuActive.bind(this);
    this.blurReset = this.blurReset.bind(this);
    this.getMakePathURL = this.getMakePathURL.bind(this)

    this.activeKey = '';
    this.scrollKey = '';

    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.activeKey && this.scrollKey) {
      this.handleMenuActive(this.activeKey, this.scrollKey);
    }
  }

  componentDidUpdate() {
    this.hoverReset();
    if (this.activeKey && this.scrollKey) {
      this.handleMenuActive(this.activeKey, this.scrollKey);
    }
    this.blurReset();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleMenuActive(key, scrollKey) {
    if (typeof window === 'undefined') {
      return;
    }

    const scripts = document.getElementsByTagName('script');
    const scriptFirst = _.get(scripts, '0');
    if (_.findIndex(scripts, { src: `${window.location.origin}/jquery-3.6.0.min.js` }) < 0) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/jquery-3.6.0.min.js';
      script.onload = () => {
        this.handleMenuActive(key, scrollKey); // jQueryがロードされた後に再実行
      };
      scriptFirst.parentNode.insertBefore(script, scriptFirst);
    }

    if (typeof jQuery !== 'undefined') {
      const scroll = jQuery(jQuery(scrollKey).get(0));
      const active = scroll.find(`.${key}`);
      if (active && active.position()) {
        const activeWidth = active.width() / 2;

        let pos = active.position().left + activeWidth;
        const scrollPos = scroll.scrollLeft();
        const scrollWidth = scroll.width();

        pos = pos + scrollPos - scrollWidth / 2 + 24;

        scroll.animate(
          {
            scrollLeft: pos,
          },
          0,
        );
      }
    }
  }

  blurReset() {
    document.activeElement.blur();
  }
  hoverReset() {
    const browserInfo = this.context.getModelData('browserInfo');
    if (typeof window === 'object') {
      let targetList = document.getElementsByClassName('header-menu-list');
      if (this.context.spMode || browserInfo.isIOS || browserInfo.isAndroid) {
        for (let i = 0; i < targetList.length; i++) {
          const targetItem = targetList[i];
          targetItem.style.opacity = 1;
        }
      }
    }
  }

  /**
   * カテゴリ毎のURL(パス)を生成
   * @param {*} val 
   * @return URL
   */
  getMakePathURL(val) {
    if (!val) {
      val = 'all'; 
    }
    let to = routes.newTrenta;
    let params = { categoryCode: val };
    if (_.get(this.context, 'routeHandler.isEachTrenta', false)) {
      to = routes.newEachTrenta;
      params.companyCode = _.get(this.context, 'routeHandler.params.companyCode');
    } else if (_.get(this.context, 'routeHandler.isEachCompany')) {
      to = routes.newEachCompany;
      params.companyCode = _.get(this.context, 'routeHandler.params.companyCode');
    }
    return to.makePath(params);
  }

  render() {
    let items;
    const self = this;
    if (this.props.headerMenu) {
      const uiView = _.get(this.context.routeHandler, 'uiView');
      const categoryTag = _.get(this.props, 'categoryTag');
      items = _.compact(
        _.map(this.props.list || [], (itemData, index) => {
          if (!itemData || !itemData.title) return;
          let isActive;
          isActive =
            this.context.routeHandler.url === encodeURI(itemData.link_url) ||
            ((uiView === 'special' || uiView === 'specialdetail') && itemData.link_url.match(/^\/feature/)) ||
            ((uiView === 'local' || uiView === 'localDetail') && itemData.link_url.match(/^\/nnn/)) ||
            ((uiView === 'articles' ||
              uiView === 'ArticlesContentImages' ||
              uiView === 'articleDetail' ||
              uiView === 'ArticleDetailContentImages') &&
              categoryTag === itemData.title);
          if (isActive) {
            self.activeKey = `header-menu-${index}`;
            self.scrollKey = '.header-menu-block';
          }
          return (
            <li key={`header-menu-${index}`} className={`header-menu header-menu-${index}`}>
              <MainViewLink href={itemData.link_url} className="header-menu-list">
                {isActive && !_.get(this.context.routeHandler, 'scrollTag') ? (
                  <h1
                    className={classnames({
                      active: isActive,
                      'detailmenu-list': this.context.spMode,
                      'menu-list': !this.context.spMode,
                    })}
                  >
                    {itemData.title}
                  </h1>
                ) : (
                  <span
                    className={classnames({
                      active: isActive,
                      'detailmenu-list': this.context.spMode,
                      'menu-list': !this.context.spMode,
                    })}
                  >
                    {itemData.title}
                  </span>
                )}
              </MainViewLink>
            </li>
          );
        }),
      );

      return (
        <React.Fragment>
          <div className="menu-wrapper">
            <div className="menu-block header-menu-block">
              <ul className="menu-contents category-menu">{items}</ul>
            </div>
          </div>
        </React.Fragment>
      );
    }
    // TODO: props.localMenuを渡してくるコンポーネントないかも？
    if (this.props.localMenu) {
      let routerUrl = this.context.routeHandler.url;
      routerUrl = routerUrl.replace(/\?.+/, '');
      items = _.compact(
        _.map(this.props.list || [], (itemData, index) => {
          if (!itemData || !itemData.title) return;
          let isActive = false;
          isActive = routerUrl === encodeURI(itemData.link_url);
          if (isActive) {
            self.activeKey = `local-menu-${index}`;
            self.scrollKey = '.detailmenu-contents';
          }
          return (
            <li key={`local-menu-${index}`} className={`local-menu-${index}`}>
              <MainViewLink href={itemData.link_url}>
                <span className={classnames('detailmenu-list', { active: isActive })}>{itemData.title}</span>
              </MainViewLink>
            </li>
          );
        }),
      );

      return (
        <React.Fragment>
          <div className="detailmenu-wrapper">
            <div className="scroll-wrapper">
              <ul className="detailmenu-contents">{items}</ul>
            </div>
          </div>
        </React.Fragment>
      );
    }

    if (this.props.rankingMenu) {
      let list = this.props.list;
      const defaultList = [
        {
          title: '総合',
          link_url: '/ranking/all',
        },
      ];
      if (defaultList.length) {
        list = defaultList.concat(list);
      }
      items = _.compact(
        _.map(list || [], (itemData, index) => {
          if (!itemData || !itemData.title) return;
          let isActive = false;
          isActive = this.context.routeHandler.url === encodeURI(itemData.link_url);
          if (isActive) {
            self.activeKey = `ranking-menu-${index}`;
            self.scrollKey = '.ranking-menu-contents';
          }
          return (
            <li key={`ranking-menu-${index}`} className={`ranking-menu-${index}`}>
              <MainViewLink href={itemData.link_url} className="ranking-menu-nav-btn">
                {isActive ? (
                  <h2 className={classnames('ranking-menu-item', { active: isActive })}>{itemData.title}</h2>
                ) : (
                  <span className="ranking-menu-item">{itemData.title}</span>
                )}
              </MainViewLink>
            </li>
          );
        }),
      );

      return (
        <div className="ranking-menu">
          <div className="scroll-wrapper">
            <div className="ranking-menu-contents-wrapper">
              <ul className="ranking-menu-contents">{items}</ul>
            </div>
          </div>
        </div>
      );
    }

    //　新着一覧
    if (this.props.categoryTag) {
      const isEachCompany = _.get(this.context, 'routeHandler.isEachCompany');
      let list = this.props.list;
      const defaultList = [
        {
          title: 'すべて',
          link_url: this.getMakePathURL('all'),
        },
      ];
      if (defaultList.length) {
        list = defaultList.concat(list);
      }
      items = _.compact(
        _.map(list || [], (itemData, index) => {
          if (!itemData || !itemData.title || itemData.title === '特集') return;
          //categoryListのlink_urlからcategoryCodeを取得
          let value = itemData.link_url.split('/').pop();
          // 各社UI YTV・CTVの場合、 カテゴリコード「culture」 => 「entertainment」利用
          if (value === 'culture' && _.get(this.context, 'routeHandler.isEachCompany') && _.includes(['ytv', 'ctv'], _.get(this.context, 'routeHandler.params.companyCode'))) {
            value = 'entertainment';
            itemData.title = _.get(this.context, 'routeHandler.params.companyCode') === 'ctv' ? 'エンタメ' : '芸能';
          }
          const linkUrl = this.getMakePathURL(value);
          let isActive = false;
          isActive = this.context.routeHandler.url === linkUrl;
          if (isActive) {
            self.activeKey = `newContent-menu-${index}`;
            self.scrollKey = '.newContent-menu-contents';
          }
          return (
            <li key={`newContent-menu-${index}`} className={`newContent-menu-${index}`}>
              <MainViewLink href={linkUrl} className="newContent-menu-nav-btn">
                {isActive ? (
                  <h2 className={classnames('newContent-menu-item', { active: isActive, 'each-company-active': isEachCompany && isActive })}>{itemData.title}</h2>
                ) : (
                  <span className="newContent-menu-item">{itemData.title}</span>
                )}
              </MainViewLink>
            </li>
          );
        }),
      );

      return (
        <div className="newContent-menu">
          <div className="scroll-wrapper">
            <div className="newContent-menu-contents-wrapper">
              <ul className="newContent-menu-contents">{items}</ul>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default withModalContext(ScrollMenu);
