import PropTypes from 'prop-types';
import TitleCard from './TitleCard';
import React from 'react';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import _ from 'lodash';
import { getGenres2Name, getGenres2Code } from '../../../../common/getGenreCategories';
import MainViewLink from "../../../common/components/MainViewLink";
import routes from "../../../common/routes";

const PERPAGE = 3;
class RelativeCategoryArticleList extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
  };

  static getRootPath = function(models, options, props) {
    return ['article', 'sameCategory', _.get(props, 'routeHandler.params.id')];
  };

  static getPaths = function(models, options, props) {
    let paths = [];

    const indexes = function (models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum
            : options && options.numTitles
            ? options.numTitles + (props.fromNum || 0)
            : PERPAGE - 1,
      };
    };

    const rootPath = this.getRootPath(models, options, props);

    paths = paths.concat([
      rootPath.concat([indexes(models, options, props), ['article_id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at']]),
      rootPath.concat(['detail']),
    ]);

    return paths;
  };

  constructor(props, context) {
    super(props, context);

    this.category = getGenres2Name(_.get(this.props, 'articles.detail.genres'), _.get(context, 'models.config.data'));
    this.items = _.get(props, 'articles');

    this.state = {
      dispose: null,
      fetchDataError: null,
      loading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.items || !this.category) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
  }

  fetchData(props = this.props, context = this.context) {
    const paths = this.constructor.getPaths(context.models, {}, props);

    if (this.state.dispose) {
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;
    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    this.setState({ loading: true });

    let rootPath = this.constructor.getRootPath(context.models, {}, props);
    if (rootPath && rootPath.length) rootPath = rootPath.join('.');

    evaluator
      .then(res => {
        this.items = _.get(res, `json.${rootPath}`, {});
        this.item = _.get(res, `json.${rootPath}.detail`, {});
        this.category = getGenres2Name(_.get(this.item, 'genres'), _.get(context, 'models.config.data'));
        this.categoryCode = getGenres2Code(_.get(this.item, 'genres'), _.get(context, 'models.config.data'));

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          loading: false,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }


  render() {
    if (!this.items || !this.category) return null;

    let items = [];
    items = _.compact(
      _.map(this.items || [], (itemData, index) => {
        if (!itemData || !itemData.source_systems || !itemData.title || index === 'detail') return;
        return (
          <TitleCard
            scroll={this.context.spMode}
            key={`relative_category_article_card_${itemData.source_systems.id}_${index}`}
            itemData={itemData}
            default={!this.context.spMode}
          />
        );
      }),
    );

    if (!items.length) return null;

    const spBlock = (
      <React.Fragment key="relative-category-article-list">
        <div className="contentsWrapper">
          <div className="category-main-title-block">
            <div className="category-main-title">{this.category}</div>
            {this.categoryCode && (
              <MainViewLink
                className="btn btn-fill base-color btn-small btn-category"
                to={routes.new}
                params={{ categoryCode: this.categoryCode }}
              >
                すべて見る
              </MainViewLink>
            )}
          </div>
          <div className="scroll-wrapper">
            <div className="scroll-list">
              {items}
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    const pcBlock = (
      <React.Fragment key="relative-category-article-list">
        <div className="category-main-title-block">
          <div className="category-main-title">{this.category}</div>
          {this.categoryCode && (
            <MainViewLink
              className="btn btn-fill base-color btn-small btn-category"
              to={routes.new}
              params={{ categoryCode: this.categoryCode }}
            >
              すべて見る
            </MainViewLink>
          )}
        </div>
        <div className="title-card-wrapper">
          {items}
        </div>
      </React.Fragment>
    );

    return this.context.spMode ? spBlock : pcBlock;
  }
}
const root = withLayoutContext(RelativeCategoryArticleList);
root.getPaths = RelativeCategoryArticleList.getPaths;
root.getRootPath = RelativeCategoryArticleList.getRootPath;
export default root;
