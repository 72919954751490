import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import url from "url";

class StreaksPlayer extends Component {
  static propTypes = {
  };

  static contextTypes = {
    models: PropTypes.object,
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.coreRef = React.createRef();
    this.state = {};

    if (_.get(props, 'item')) {
      if (!_.get(props, 'item.poster.src') && _.get(props, 'image.url')) {
        const urlParse = url.parse(props.image.url, true);
        urlParse.query.w = _.get(context, 'spMode') ? 400 : 700;
        _.set(props, 'item.poster.src', url.format(urlParse));
      } else if (_.get(props, 'item.poster.src')) {
        const urlParse = url.parse(props.item.poster.src, true);
        urlParse.query.w = _.get(context, 'spMode') ? 400 : 700;
        _.set(props, 'item.poster.src', url.format(urlParse));
      }
    }
  }

  componentDidMount() {
    const self = this;
    const styles = document.getElementsByTagName('link');
    const styleFirst = _.get(styles, '0');
    if (_.findIndex(styles, { href: 'https://players.streaks.jp/sdk/v2.3.0/streaksplayer.min.css' }) < 0) {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = 'https://players.streaks.jp/sdk/v2.3.0/streaksplayer.min.css';
      styleFirst.parentNode.insertBefore(style, styleFirst);
    }
    let scripts = document.getElementsByTagName("script");
    const scriptFirst = _.get(scripts, '0');
    if (_.findIndex(scripts, { src: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js' }) < 0) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://imasdk.googleapis.com/js/sdkloader/ima3.js';
      scriptFirst.parentNode.insertBefore(script, scriptFirst);
    }
    if (_.findIndex(scripts, { src: 'https://players.streaks.jp/sdk/v2.3.0/streaksplayer.min.js' }) < 0) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://players.streaks.jp/sdk/v2.3.0/streaksplayer.min.js';
      scriptFirst.parentNode.insertBefore(script, scriptFirst);
      script.onload = () => {
        self.play();
      };
    } else {
      self.play();
    }
    this._isMounted = true;
  }

  componentDidUpdate() {}
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
    this._isMounted = false;
  }

  play() {
    if (this.coreRef.current) {
      while (this.coreRef.current.firstChild) {
        this.coreRef.current.removeChild(this.coreRef.current.firstChild);
      }
    }

    const video = document.createElement('video');
    video.setAttribute('controls', 'enabled');
    video.setAttribute('preload', 'none');
    this.coreRef.current.appendChild(video);
    const options = {
      startTime: 0,
      autoplay: true,
      preload: 'none',
      chromeless: false,
      advertising: {
        requestMode: 'ondemand',
      },
    };
    this.player = streaksplayer(video, options, () => {});
    const self = this;
    this.player.ready(() => {
      if (_.get(self.props, 'handlePlayerReady')) {
        self.props.handlePlayerReady(
          self.player || _.get(self.coreRef, 'current.player'),
          self.props,
          false,
        );
      }
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    return <div className="video-core-container" ref={this.coreRef}></div>;
  }
}

export default StreaksPlayer;
