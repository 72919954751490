import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import * as browserEvents from '../../../sketch-platform/utils/browserEvents';
import ErrorBoundary from '../../common/components/ErrorBoundary';
import Footer from '../../common/components/Footer';
import Header from '../../common/components/Header';
import PageTransitionProgressBar from '../../common/components/PageTransitionProgressBar';
import LayoutContext from '../../common/context/LayoutContext';
import htmlContext from '../../common/hocs/htmlContext';
import getColumnsInRow from '../../common/utils/getColumnsInRow';
import FreezeWrapper from './FreezeWrapper';
import TitleCard from '../components/browse/TitleCard';
import HomeSpecialContent from '../components/browse/HomeSpecialContent';
import RankingList from '../components/browse/RankingList';
import RecommendList from '../components/browse/RecommendList';
import EachStation from '../components/browse/EachStation';
import LiveCard from '../components/browse/LiveCard';
import BannerList from '../components/browse/BannerList';
import RelativeCategoryArticleList from '../components/browse/RelativeCategoryArticleList';
import BackgroundImage from '../../common/components/BackgroundImage';
import GoogleAdManager from '../../common/components/GoogleAdManager';
import getImage from '../../../common/getImage';
import { getThumbResizeImage, getCardResizeImage, featurePreloadImages, getPreloadLinkTags, featureMainImage } from '../../../common/preloadImages';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { getGenre2NameCode, getArticle2ProviderCode } from '../../../common/getGenreCategories';
import TaboolaWidget from '../../common/components/TaboolaWidget';
import CompanyHomeAttentionContent from '../components/browse/CompanyHomeAttentionContent';
import HeadlineListEachTrenta from '../components/browse/HeadlineListEachTrenta';
import MainViewLink from '../../common/components/MainViewLink';
import FeatureArticles from '../components/browse/FeatureArticles';
import { getSearchArticleIdWithPrefix } from '../../../common/getSourceProjectId';
import getBroadcasterData from '../../../common/getBroadcasterData';
import routes from '../../common/routes';
import categoryCode2Name from '../../../common/categoryCode2Name';
import url from 'url'
import convertAdTagUiViewName from '../../common/utils/convertAdTagUiViewName';
import HeaderBreadcrumb from '../../common/components/HeaderBreadcrumb';
import EmergencyContents from '../../common/components/EmergencyContents';

class FreezeOnChangePageKey extends React.PureComponent {
  static contextTypes = {
    pageKey: PropTypes.string.isRequired,
  };

  render() {
    return this.props.children;
  }
}

class BlueroseLayout extends Component {
  static childContextTypes = {
    pageKey: PropTypes.string,
    columnsInRow: PropTypes.number,
    routeHandlers: PropTypes.array,
    spMode: PropTypes.bool,
    isSpaError: PropTypes.func,
    getNtvHeadlineNews: PropTypes.object,
    getAttention: PropTypes.object,
    getHeader: PropTypes.object,
    getFooter: PropTypes.object,
    projectRefIdMapping: PropTypes.object,
    companyLinks: PropTypes.array,
    topText: PropTypes.string,
    live: PropTypes.object,
  };

  static contextTypes = {
    models: PropTypes.object,
    getModelData: PropTypes.func,
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    cookies: PropTypes.object,
    service: PropTypes.string,
    isIframe: PropTypes.bool,
    isStore: PropTypes.bool,
    providerList: PropTypes.array
  };

  static getPaths = function (models, options, props) {
    let paths = [];
    let companyCodePath;
    const listJsonProviderList = _.get(models, 'config.data.providerList', []);
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');
    if (_.get(props, 'routeHandler.isEachCompany') && !_.includes(listJsonProviderList, companyCode)) {
      companyCodePath = companyCode;
    } else {
      companyCodePath = 'ntv';
    }
    // TRENTA UI 「記事広告」記事、各社UI はrankingのカテゴリはallで固定
    let rankingCategory = companyCodePath === 'ntv' ? _.get(props, 'routeHandler.params.categoryCode', 'all')  : 'all';
    if(_.get(props,'routeHandler.params.categoryCode') === "sponsored") {
      rankingCategory = "all"
    }

    paths = paths.concat([
      ['link', 'ntv', 'header_menu'],
      ['link', 'ntv', 'local_broadcast'],
      ['link', companyCodePath, 'nnn_banner_link'],
      ['link', companyCodePath, 'nnn_live_module'],
      ['link', companyCodePath, 'nnn_induction_module'],
      ['link', companyCodePath, 'nnn_urgent_news_module'],
      ['link', 'ntv', 'nnn_header_menu'],
      ['link', 'ntv', 'nnn_category_menu'],
      ['article', 'projectRefIdMapping'],
      [
        'article',
        'ranking',
        _.get(props, 'routeHandler.isEachCompany', false)
          ? _.get(props, 'routeHandler.params.companyCode', 'ntv')
          : 'ntv',
        rankingCategory,
        { from: 0, to: 9 },
        ['article_id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at'],
      ],
      ['article', 'getNtvHeadlineNews'],
      ['article', 'getAttention'],
      ['link', companyCodePath, 'nnn_footer_link'],
      ['link', 'ntv', 'nnn_ranking_menu'],
      ['config', 'flux'],
      ['config', 'listJsonProviderList'],
    ]);

    if (_.get(props.routeHandler, 'isEachCompany')) {
      paths = paths.concat([
        ['article', 'getHeader', companyCodePath],
        ['article', 'getFooter', companyCodePath],
      ]);
    }

    if (_.get(props.routeHandler, 'uiView') === 'home') {
      paths = paths.concat([
        ['tag', 'feature', companyCodePath, 'home', { from: 0, to: 7 }, ['tag_id', 'thumbnail']],
        ['link', companyCode, 'nnn_each_company_link'],
      ]);
    }

    if (_.get(props.routeHandler, 'uiView') === 'companyHome') {
      paths = paths.concat([
        ['tag', 'feature', companyCodePath, 'home', { from: 0, to: 7 }, ['tag_id', 'thumbnail']],
      ]);
    }

    if (
      _.get(props, 'routeHandler.uiView') === 'articles' ||
      _.get(props, 'routeHandler.uiView') === 'ArticlesContentImages' ||
      _.get(props, 'routeHandler.uiView') === 'articleDetail' ||
      _.get(props, 'routeHandler.uiView') === 'ArticleDetailContentImages'
    ) {
      paths = paths.concat([
        ['article', 'sameCategory', props.id, 'detail'],
        [
          'article',
          'sameCategory',
          props.id,
          { from: 0, to: 2 },
          ['article_id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at'],
        ],
      ]);
      if (_.get(props, 'routeHandler.isEachCompany')) {
        ['article', 'headline', companyCodePath, 'eachCompany'];
      }
    }

    if (_.get(props, 'routeHandler.uiView') === 'articles' || _.get(props, 'routeHandler.uiView') === 'articleDetail') {
      const id = _.get(props, 'routeHandler.params.id');
      const isTrentaUiType = !_.get(props.routeHandler, 'isEachCompany', false);
      if (id) {
        paths = paths.concat([
          ['article', id, isTrentaUiType],
          [
            'article',
            'ranking',
            'byId',
            id,
            { from: 0, to: 9 },
            ['article_id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at'],
          ],
        ]);
      }
    }

    if (
      _.get(props, 'routeHandler.uiView') === 'ArticlesContentImages' ||
      _.get(props, 'routeHandler.uiView') === 'ArticleDetailContentImages'
    ) {
      const id = _.get(props, 'routeHandler.params.id');
      if (id) {
        paths = paths.concat([
          ['article', 'images', id],
          [
            'article',
            'ranking',
            'byId',
            id,
            { from: 0, to: 9 },
            ['article_id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at'],
          ],
        ]);
      }
    }

    if (_.get(props, 'routeHandler.uiView') === 'specialdetail') {
      paths = paths.concat([
        ['feature', companyCodePath, _.get(props, 'routeHandler.params.tagName')],
      ]);
    }

    if (_.get(props, 'routeHandler.uiView') === 'live') {
      paths = paths.concat([
        ['config','live'],
      ]);
    }

    return paths;
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      calcColumn: false,
      columnsInRow: 5,
      spMode: false,
      overlayHeaderNavigation: false,
      scrollOffset: 0,
      modalComponents: [],
      dispose: null,
      fetchDataError: null,
      invisibleAd: false,
      viewOverlayAd: true,
    };

    // 共通テキスト
    this.topText = '日テレNEWS NNN｜日本テレビ系NNN30局のニュースサイト';

    this.preloadImagesArray = [];

    // TRENTA UI記事詳細ページで表示中の記事の出稿局のcompanyCodeをthis.companyCodeに代入
    if (
      _.get(props, 'routeHandler.uiView') === 'articles' ||
      _.get(props, 'routeHandler.uiView') === 'ArticlesContentImages' ||
      _.get(props, 'routeHandler.uiView') === 'articleDetail' ||
      _.get(props, 'routeHandler.uiView') === 'ArticleDetailContentImages'
    ) {
      const articleId = _.get(props.routeHandler, 'params.id');
      const providerPrefix = _.get(context, 'models.config.data.providerPrefix', {});
      const $providerPrefix = getSearchArticleIdWithPrefix(articleId, providerPrefix);
      const provider = !_.isEmpty($providerPrefix.prefix) ? _.get($providerPrefix, 'prefix') : 'ntv';
      this.companyCode = getBroadcasterData(provider).title;
    }

    const browserInfo = this.context.getModelData('browserInfo');
    // スマホの場合は予め2を指定する
    if (
      (browserInfo.isAndroid || browserInfo.isIOS) &&
      !(browserInfo.isAndroidTablet || browserInfo.isFireTablet || browserInfo.isIPad)
    ) {
      this.state.columnsInRow = 2;
      this.state.spMode = true;
    }

    this.layoutRef = React.createRef();

    this.showModal = this.showModal.bind(this);
    this.isShowModal = this.isShowModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showSideMenu = this.showSideMenu.bind(this);
    this.closeSideMenu = this.closeSideMenu.bind(this);
    this.checkMediaQuery = _.throttle(this.checkMediaQuery.bind(this), 300, { leading: true, trailing: true });
    this.handleSmpScroll = this.handleSmpScroll.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollStart = this.handleScrollStart.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.handleOverlayHeaderNavigation = this.handleOverlayHeaderNavigation.bind(this);
    this.handleChangeLaunchAppPath = this.handleChangeLaunchAppPath.bind(this);
    this.getPreloadImages = this.getPreloadImages.bind(this);
    this.setPreloadImages = this.setPreloadImages.bind(this);
    this.getJsonArticlesPreloadImage = this.getJsonArticlesPreloadImage.bind(this);
    this.getOverlayAdModules = this.getOverlayAdModules.bind(this);

    this.scrollToTop = this.scrollToTop.bind(this);
    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    this.getModelData = this.getModelData.bind(this);
    this.isSpaError = this.isSpaError.bind(this);
    this.handleAdCloseClick = this.handleAdCloseClick.bind(this);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.getModelData();

    this.listJsonProviders = this.model.getSync(['config', 'listJsonProviderList']);
  }

  getModelData(props = this.props, context = this.context) {
    const isEachCompany = _.get(props.routeHandler, 'isEachCompany');
    const spMode = _.get(this.state, 'spMode');
    const uiView = props.routeHandler?.uiView;
    let companyCode; // TRENTA UIもしくはlist.json版画面では'ntv'固定
    const $companyCode = _.get(props, 'routeHandler.params.companyCode', 'ntv');
    if (_.get(props, 'routeHandler.isEachCompany')) {
      if (_.includes(this.listJsonProviders, $companyCode)) {
        companyCode = 'ntv';
      } else {
        companyCode = $companyCode;
      }
    } else {
      companyCode = 'ntv';
    }
    // GALLERYリンク集取得
    const link = this.model.getSync(['link', companyCode]);
    this.articleDetail = null;
    this.trentaHeaderMenu = _.get(link, 'nnn_header_menu');
    this.trentaCategoryMenu = _.get(link, 'nnn_category_menu');
    this.headerMenu = _.get(link, 'header_menu');
    this.localBroadcast = _.get(link, 'local_broadcast');
    this.ntvBannerLink = _.get(link, 'nnn_banner_link');
    this.footerLink = _.get(link, 'nnn_footer_link');
    this.liveLink = _.get(link, 'nnn_live_module');

    // set logo as preload on TRENTA UI
    if(!isEachCompany) this.setPreloadImages('/images/logo.svg');

    // images used on LiveCard
    const liveLogo = !isEachCompany ?
      '/images/live-logo.svg' :
      _.get(this.liveLink, '1.image_url', _.get(getBroadcasterData(companyCode), 'img_src.l'));
    if(!(isEachCompany && spMode) || _.includes(['articleDetail', 'live'], uiView)) this.setPreloadImages([liveLogo]);
    const posterUrl = `https://news.ntv.co.jp/images/live_thumbnail_pc.jpg`;
    const livePoster = spMode ? posterUrl.replace('live_thumbnail_pc', 'live_thumbnail_sp') : posterUrl;
    const livePosterUrl = !isEachCompany ? livePoster : _.get(this, 'liveLink.0.image_url' , null);
    if (!(isEachCompany && spMode || uiView === 'live') || uiView === 'articleDetail') this.setPreloadImages([livePosterUrl]);
    _.set(this.liveLink, '0.poster_url', posterUrl);

    this.urgentNewsLink = _.get(link, 'nnn_urgent_news_module');

    // 誘導枠
    this.inductionLink = _.get(link, 'nnn_induction_module');

    if (this.urgentNewsLink?.length) {
      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.tz.setDefault('Asia/Tokyo');
      const now = dayjs().tz().format();
      this.urgentNewsLink = _.slice(
        _.compact(
          _.map(this.urgentNewsLink, (link) => {
            if (
              (!_.get(link, 'publish_start_date') && !_.get(link, 'publish_end_date')) ||
              (_.get(link, 'publish_start_date') && link.publish_start_date > now) ||
              (_.get(link, 'publish_end_date') && link.publish_end_date < now)
            ) {
              return null;
            }
            return link;
          }),
        ),
        0,
        3,
      );
    }

    // 各社UIの場合はrankingのカテゴリはallで固定
    let rankingCategory = companyCode === 'ntv' ? _.get(props, 'routeHandler.params.categoryCode', 'all') : 'all';
    if(_.get(this.context,'routeHandler.params.categoryCode') === "sponsored") {
      rankingCategory = "all"
    }
    this.rankingArticles = this.model.getSync(['article', 'ranking', companyCode, rankingCategory]);
    this.projectRefIdMapping = this.model.getSync('article.projectRefIdMapping');
    // NTVヘッドライン取得: 各社UI: 各社TOP, 記事詳細, 画像詳細
    if (
      uiView === 'companyHome' ||
      _.includes(['articles', 'articleDetail', 'ArticlesContentImages', 'ArticleDetailContentImages', 'ArticleDetailContentImagesEachCompany'], uiView) && isEachCompany
    ) {
      const ntvHeadline = this.model.getSync('article.getNtvHeadlineNews');
      this.getNtvHeadlineNews = _.take(ntvHeadline, 6);
      // preload imageの設定: 全国のヘッドライン
      this.getJsonArticlesPreloadImage(this.getNtvHeadlineNews, true);
    }
    // 注目の記事取得: TRENTA UI: 総合TOP, 各社TOP, 記事詳細, 画像詳細
    if (
      uiView === 'home' ||
      _.includes(['articles', 'articleDetail', 'ArticlesContentImages', 'ArticleDetailContentImages'], uiView) && !isEachCompany
    ) {
      const attentions = this.model.getSync('article.getAttention');
      this.getAttention = _.take(attentions, 6)
      // preload imageの設定: 注目の記事
      this.getJsonArticlesPreloadImage(this.getAttention);
    }
    this.live = this.model.getSync('config.live');

    // preload imageの設定: 誘導モジュール (encodeURI)
    if (this.inductionLink?.length) {
      const introductionImages = _.map(this.inductionLink, itemData => {
        const image_url = getImage({ itemData, introduction: true }, this.context);
        return encodeURI(_.get(this.getResizedUrlFormat({ image_url }, 200), 'image_url'));
      });
      // PC版もしくはSP版の記事詳細でpreloadにset
      if (
        introductionImages?.length && (!spMode || _.includes(['articles', 'articleDetail'], uiView))
      ) this.setPreloadImages(introductionImages);
    }

    if (this.ntvBannerLink) {
      this.ntvBannerLink = _.map(this.ntvBannerLink, link => this.getResizedUrlFormat(link, 320));
      // preload imageの設定: バナーモジュール
      const bannerImages = _.compact(_.map(this.ntvBannerLink, e => e.image_url));
      if (bannerImages?.length && !(isEachCompany && spMode)) this.setPreloadImages(bannerImages);
    }

    // ランキング（rel="preload"）取得処理(liveページ/ランキングデータあり/PCのみ)
    if (
      uiView !== 'ranking' && this.rankingArticles?.length &&
      (!spMode || _.includes(['home', 'companyHome', 'articleDetail', 'articles'], uiView))
      ) {
      const rankingImages = Object.values(this.rankingArticles).filter((e, i) => (i < 5 ? e : false)); // ranking表示画像は５つまで
      const rankingArticleImages = _.compact(
        _.map(rankingImages, itemData => getThumbResizeImage({ itemData, ranking: true }, {...context, spMode: this.state.spMode})),
      );
      this.setPreloadImages(rankingArticleImages);
    }

    // 各社UIヘッダーフッター取得
    if (isEachCompany) {
      if (_.isEmpty(this.eachCompanyHeader)) this.eachCompanyHeader = this.model.getSync(['article', 'getHeader', companyCode]);
      if (_.isEmpty(this.eachCompanyFooter)) this.eachCompanyFooter = this.model.getSync(['article', 'getFooter', companyCode]);
    }

    // 各局サイトリンク取得: 各社TOP（TRENTA UI）で使用
    this.companyLinks = [];
    if (uiView === 'home') this.companyLinks = this.model.getSync(['link', $companyCode, 'nnn_each_company_link']);

    // 特集棚出し: 総合TOP, 各社TOP（TRENTA UI, 各社UI）, 記事詳細（各社UI）で使用
    if (_.includes(['home', 'companyHome'], uiView) || (uiView === 'articleDetail' && isEachCompany)) {
      this.featureTags = this.model.getSync(`tag.feature.${companyCode}.home`);

      // 特集一覧のJSONから先頭3件を絞る
      if (this.featureTags) {
        const featureListArr = Object.keys(this.featureTags).map((key) => {
          return this.featureTags[key];
        });
        const slicedFeatureListArr = _.slice(featureListArr, 0, 3);

        const param = context;
        param.spMode = spMode;
        // preload imageの設定：特集棚出し
        this.setPreloadImages(featurePreloadImages(slicedFeatureListArr, param));
      }
    }

    // 記事カテゴリ取得, ヘッドライン取得: 記事詳細（TRENTA UI, 各社UI）, 画像詳細（TRENTA UI, 各社UI）で使用
    if (_.includes(['articles', 'ArticlesContentImages', 'articleDetail', 'ArticleDetailContentImages'], uiView)) {
      const id = _.get(props, 'routeHandler.params.id');
      if (id) {
        const isTrentaUiType = !_.get(props.routeHandler, 'isEachCompany', false);
        this.articleDetail = this.model.getSync(`article.${id}.${isTrentaUiType}`);
        if (!this.articleDetail) {
          this.articleDetail = this.model.getSync(`article.images.${id}`);
        }

        const providerCode = _.get(this.context, 'routeHandler.isEachCompany') ? getArticle2ProviderCode(this.articleDetail) : 'ntv';
        const genre = getGenre2NameCode(_.get(this.articleDetail, 'genres'), _.get(context, 'models.config.data'), providerCode);
        if (_.get(genre, 'name')) this.articleDetail.categoryTag = _.get(genre, 'name');
        this.categoryCode = _.get(genre, 'name');
        const invisibleAd = _.get(this.articleDetail, 'custom_data.not_advert_flag') || _.get(this.context,'routeHandler.params.categoryCode') === "sponsored"  ? true : false;
        Object.assign(this.state, { invisibleAd });
      }
    }

    // 特集の情報取得: 特集記事一覧(TRENTA UI, 各社UI)で使用
    if (uiView === 'specialdetail' && spMode) {
      this.featureDetail = this.model.getSync([
        'feature',
        companyCode,
        _.get(props, 'routeHandler.params.tagName'),
      ]);
    }
  }

  // resized url format
  getResizedUrlFormat(link, size) {
    const imageUrl = _.get(link, 'image_url');
    if (imageUrl) {
      const urlParse = url.parse(imageUrl, true);
      urlParse.query.w = size;
      link.image_url = url.format(urlParse);
    }
    return link;
  }

  getChildContext() {
    const routeHandler = this.props.routeHandler || this.context.routeHandler;
    return {
      pageKey: routeHandler ? routeHandler.path : '',
      columnsInRow: this.state.columnsInRow,
      routeHandlers: this.props.routeHandlers,
      spMode: this.state.spMode,
      isSpaError: this.isSpaError,
      projectRefIdMapping: this.projectRefIdMapping,
      companyLinks: this.companyLinks,
      topText: this.topText,
      live: this.live,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const newState = {};

    this.context.history.listen(this.handleHistoryChange);

    // checkMediaQueryを呼ぶと遅いので直接処理
    newState.columnsInRow = getColumnsInRow();
    newState.calcColumn = true;
    newState.isLoadedView = true;
    if (newState.columnsInRow === 2) {
      newState.spMode = true;
    }

    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
      browserEvents.addEventListener('scroll', this.handleSmpScroll);
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
      browserEvents.addEventListener('scroll', this.handleScroll);
      browserEvents.addEventListener('scrollStart', this.handleScrollStart);
      browserEvents.addEventListener('scrollEnd', this.handleScrollEnd);
    }

    if (
      (browserInfo.isIOS || browserInfo.isAndroid) &&
      !(browserInfo.isAndroidTablet || browserInfo.isFireTablet || browserInfo.isIPad)
    ) {
      newState.spMode = true;
    }

    if (!_.isEmpty(newState)) this.setState(newState);
  }

  componentWillUnmount() {
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
      browserEvents.removeEventListener('scroll', this.handleSmpScroll);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
      browserEvents.removeEventListener('scroll', this.handleScroll);
      browserEvents.removeEventListener('scrollStart', this.handleScrollStart);
      browserEvents.removeEventListener('scrollEnd', this.handleScrollEnd);
    }

    if (this.checkMediaQueryTimerId) clearTimeout(this.checkMediaQueryTimerId);
  }

  componentDidUpdate(props, state) {
    const categoryCode = _.get(props, 'routeHandler.params.categoryCode', 'all');
    if (categoryCode) {
      this.categoryCode = categoryCode;
    }
    Object.assign(this.state, { invisibleAd: false });
    this.getModelData(props);
    if (_.get(this.props, 'routeHandler.path') !== _.get(props, 'routeHandler.path')) {
      this.isUpdatedAd = true;
    }
  }

  fetchData(props = this.props, context = this.context) {
    const paths = this.constructor.getPaths(context.models, {}, props);
  }

  isSpaError() {
    if (this.state) {
      Object.assign(this.state, { invisibleAd: true });
    }
  }

  setBreadCrumb(params) {
    this.setState({
      breadCrumbLinks: params || [],
    });
  }

  getPreloadImages() {
    return getPreloadLinkTags(this.preloadImagesArray);
  }

  setPreloadImages(images) {
    if (typeof images === 'string') images = [images];
    this.preloadImagesArray = this.preloadImagesArray.length ? this.preloadImagesArray.concat(images) : images;
  }

  // 注目の記事・全国のヘッドライン用
  getJsonArticlesPreloadImage(items, isNtvHeadline = false) {
    if (_.isEmpty(items)) return;
    const param = _.cloneDeep(this.context);
    if (isNtvHeadline) param.routeHandler.isEachCompany = false;
    param.spMode = this.state.spMode;
    const articleImages = _.compact(
      _.map(
        _.filter(items, e => e && e.source_systems),
        itemData => getCardResizeImage({ itemData }, param),
      ),
    );
    if (!_.isEmpty(articleImages)) this.setPreloadImages(articleImages);
  }

  handleHistoryChange(e) {
    if (
      _.get(this.props.routeHandler, 'uiView') === 'home' ||
      _.get(this.props.routeHandler, 'uiView') === 'companyHome'
    ) {
      this.fetchData();
    }
    if (_.get(this.props.routeHandler, 'uiView') === 'category') {
      this.fetchData();
    }

    if (
      _.get(this.props, 'routeHandler.uiView') === 'articles' ||
      _.get(this.props, 'routeHandler.uiView') === 'ArticlesContentImages' ||
      _.get(this.props, 'routeHandler.uiView') === 'articleDetail' ||
      _.get(this.props, 'routeHandler.uiView') === 'ArticleDetailContentImages'
    ) {
      this.fetchData();
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  checkMediaQuery() {
    if (typeof window === 'undefined') return;

    if (this.checkMediaQueryTimerId) clearTimeout(this.checkMediaQueryTimerId);

    this.checkMediaQueryTimerId = setTimeout(() => {
      let columnsInRow = 1;
      let spMode = false;
      columnsInRow = getColumnsInRow();
      if (columnsInRow === 2) {
        spMode = true;
      }
      const browserInfo = this.context.getModelData('browserInfo');
      if (
        (browserInfo.isAndroid || browserInfo.isIOS) &&
        !(browserInfo.isAndroidTablet || browserInfo.isFireTablet || browserInfo.isIPad)
      ) {
        spMode = true;
        Object.assign(this.state, { spMode });
      }
      if (columnsInRow !== this.state.columnsInRow) {
        if (this._isMounted) {
          this.setState({ columnsInRow, spMode, calcColumn: true });
        } else {
          Object.assign(this.state, { columnsInRow, spMode, calcColumn: true });
        }
      }
    }, 100); // 回転前の値で処理する等、安定しないので、タイマーを用いて回転後の処理になるよう調整
  }

  handleSmpScroll(e) {
    if (this.state.sideMenuComponent) return;
    if (document && this.layoutRef.current) {
      let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
      if (scrollTop > 41) {
        this.layoutRef.current.classList.add('fixedSearch');
      } else {
        this.layoutRef.current.classList.remove('fixedSearch');
      }
      if (this.state.overlayHeaderNavigation) {
        this.setState({ scrollOffset: scrollTop });
      }
    }
  }

  handleScroll(e) {
    if (document) {
      if (this.state.overlayHeaderNavigation) {
        this.setState({ scrollOffset: Math.max(document.documentElement.scrollTop, document.body.scrollTop) });
      }
    }
  }

  handleScrollStart() {
    if (document) {
      document.body.style.pointerEvents = 'none';
    }
  }

  handleScrollEnd() {
    if (document) {
      document.body.style.pointerEvents = '';
    }
  }

  renderHeader() {
    const routeHandler = this.props.routeHandler || this.context.routeHandler;
    const headerLogo = _.get(routeHandler, 'headerLogo');
    return (
      <Header
        model={this.props.model}
        routeHandler={routeHandler}
        headerMenu={this.headerMenu}
        trentaHeaderMenu={this.trentaHeaderMenu}
        trentaCategoryMenu={this.trentaCategoryMenu}
        bannerLinks={this.ntvBannerLink}
        urgentNewsLink={this.urgentNewsLink}
        invisibleAd={this.state.invisibleAd}
        categoryTag={_.get(this.articleDetail, 'categoryTag')}
        item={_.get(this.liveLink, '0')}
        headerLogo={headerLogo}
        footerLink={this.footerLink}
        eachCompanyHeader={this.eachCompanyHeader}
      />
    );
  }

  showModal(component, props) {
    const modalComponents = this.state.modalComponents || [];
    modalComponents.push(
      <ModalContent key={`modal-content-${modalComponents.length}`} {...props} onClose={(e) => this.closeModal()}>
        {component}
      </ModalContent>,
    );
    this.setState({
      modalComponents: modalComponents,
    });
  }

  isShowModal() {
    return !_.isEmpty(this.state.modalComponents);
  }

  closeModal() {
    const modalComponents = this.state.modalComponents;
    document.body.classList.remove('is-open-modal');
    if (modalComponents.length > 1) {
      this.setState({
        modalComponents: modalComponents.slice(0, modalComponents.length - 1),
      });
    } else {
      this.setState({
        modalComponents: [],
      });
    }
  }

  showSideMenu(component, props) {
    this.setState({
      sideMenuComponent: (
        <SideMenuContent {...props} onClose={(e) => this.setState({ sideMenuComponent: null })}>
          {component}
        </SideMenuContent>
      ),
    });
  }

  closeSideMenu() {
    this.setState({
      sideMenuComponent: null,
    });
  }

  handleOverlayHeaderNavigation(flug) {
    const newState = { overlayHeaderNavigation: flug };
    if (typeof window !== 'undefined') {
      newState.scrollOffset = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    }
    this.setState(newState);
  }

  handleChangeLaunchAppPath(path) {
    this.setState({ launchAppPath: path });
  }

  handleAdCloseClick() {
    if (this.state.viewOverlayAd) {
      // Taboola広告がstateの更新で表示されなくなってしまうためElement削除で対応
      const overlayElement = document.getElementsByClassName("pc-overlay-publicity")[0];
      if(!!overlayElement){
        overlayElement.remove();
      }
    }
  }

  getOverlayAdModules(isSpMode) {
    const routeHandler = this.props.routeHandler || this.context.routeHandler;
    if (!_.get(this.state, 'invisibleAd') && _.get(this.state, 'viewOverlayAd')) {
      if (isSpMode) {
        // スマホ
        return (
          <div className="big-ad-wrapper overlay-publicity" key="overlay-ad-wrapper-sp">
            <div
              className="overlay-publicity-block"
              id={`div-gpt-${convertAdTagUiViewName(this.context)}_sp_overlay`}
              key={`div-gpt-trenta_sp_overlay_${_.get(routeHandler, 'uiView')}`}
            />
          </div>
        );
      } else {
        // PC
        return (
          <div className="overlay-publicity pc-overlay-publicity" key="overlay-ad-wrapper-pc">
            <span className="u-close js-close-btn" onClick={this.handleAdCloseClick}>
              ×
            </span>
            <div
              className="overlay-publicity-block"
              id={`div-gpt-${convertAdTagUiViewName(this.context)}_pc_overlay`}
              key={`div-gpt-trenta_pc_overlay_${_.get(routeHandler, 'uiView')}`}
            />
          </div>
        );
      }
    }
  }

  render() {
    const inapp = this.context.getModelData('inapp', 'inapp');
    const routeHandler = this.props.routeHandler || this.context.routeHandler;
    const $featureDetailImage = getImage({ itemData: this.featureDetail, specialMain: true }, this.context);
    const params = this.context;
    params.spMode = this.state.spMode;
    const featureDetailImage = featureMainImage($featureDetailImage, params);
    if (featureDetailImage && _.get(this.props.routeHandler, 'uiView') === 'specialContentDetail') this.setPreloadImages(featureDetailImage);
    const uiView = _.get(routeHandler, 'uiView');
    const isEachCompany = _.get(routeHandler, 'isEachCompany', false);

    const layoutContextValue = {
      columnsInRow: this.state.columnsInRow,
      spMode: this.state.spMode,
      overlayHeaderNavigation: this.handleOverlayHeaderNavigation,
      changeLaunchAppPath: this.handleChangeLaunchAppPath,
      topText: this.topText,
      setBreadCrumb: this.setBreadCrumb,
      getPreloadImages: this.getPreloadImages,
      setPreloadImages: this.setPreloadImages,
    };

    let renderMode;
    // 半静的画面の場合： routeHandler.path.match(/^\/pages/)、旧（about）のみ対象
    if (routeHandler.path.match(/^\/pages/) && routeHandler.query && routeHandler.query.type == 'sp') {
      renderMode = 'sp-guides';
    }

    // list.jsonページかどうか
    if (routeHandler.uiView === 'home') {
      const _path = routeHandler.path;
      const splitPath = _.split(_path, '/');
      if (splitPath.length > 2) {
        const providerId = splitPath[2]; // URLから対象の放送局IDを取得
        if (_.includes(this.listJsonProviders, providerId)) {
          routeHandler.listJson = true;
        }
      }
    }
    // 記事詳細画面かどうか。ヘッダーの描画位置の判定とmainViewの親要素へのclassNameの付与の判定に使用する。
    const isArticleDetail = _.includes(['articles', 'articleDetail'], uiView);
    const isSponsored = _.get(this.context, 'routeHandler.params.categoryCode') === 'sponsored';
    console.log({ isSponsored }, _.get(this.context, 'routeHandler.params.categoryCode'));

    const articlesPagesList = ['articles', 'ArticlesContentImages', 'articleDetail', 'ArticleDetailContentImages', 'ArticleDetailContentImagesEachCompany'];
    const allTopPagesList = ['home', 'companyHome'];

    // PCでヘッダーの広告を表示するか
    const isShowAd4PC = !_.get(this.state, 'invisibleAd') && !_.get(this.context, 'spMode') && !_.includes(allTopPagesList, uiView);
    const adClassNameOnArticles = _.get(this.context, 'spMode') && (
      uiView === 'articles' ||
      uiView === 'ArticlesContentImages' ||
      uiView === 'articleDetail' ||
      uiView === 'ArticleDetailContentImages'
    ) ? "header-publicity-articles" : null;

    return (
      <React.Fragment>
        {/* オーバーレイ広告 */}
        {this.getOverlayAdModules(_.get(this.state, 'spMode', false))}

        {/* コンテンツ本体 */}
        <div
          ref={this.layoutRef}
          className={classnames('bd', 'lang_ja', {
            inapp: inapp,
            articleDetailContentsWrapper: isArticleDetail,
            isSponsored: isSponsored,
          })}
          lang="ja-JP"
          onClick={(e) => {
            if (this._handleClickCallback) this._handleClickCallback(e);
          }}
        >
          <LayoutContext.Provider value={layoutContextValue}>
            {/* <FreezeWrapper>
              <FreezeOnChangePageKey key={routeHandler.path}>
                <PageTransitionProgressBar history={this.context.history} routeHandler={routeHandler} />
              </FreezeOnChangePageKey>
            </FreezeWrapper> */}
            {!isArticleDetail && renderMode != 'sp-guides' && this.renderHeader()}
            <TransitionGroup
              className={classnames('mainView', {
                iframe: this.context.isIframe,
                special: _.get(routeHandler, 'uiView') === 'special',
                live: _.get(this.context, 'routeHandler.uiView') === 'live',
                new: _.get(this.context, 'routeHandler.uiView') === 'new',
                info: _.get(this.context, 'routeHandler.uiView') === 'info',
                informationDetail: _.get(this.context, 'routeHandler.uiView') === 'InformationContentDetail',
                local: _.get(this.context, 'routeHandler.uiView') === 'local',
                tagResults: _.get(this.context, 'routeHandler.uiView') === 'tag',
                searchResults: _.get(this.context, 'routeHandler.uiView') === 'search',
                snsContent: _.get(this.context, 'routeHandler.uiView') === 'sns',
                staticPage: _.get(this.context, 'routeHandler.uiView') === 'SemiStaticPages',
                top: _.get(this.context, 'routeHandler.uiView') === 'home',
                eachTop: _.get(this.context, 'routeHandler.uiView') === 'companyHome',
                spArticlesContentImages:
                  _.get(this.state, 'spMode') &&
                  (_.get(routeHandler, 'uiView') === 'ArticlesContentImages' ||
                    _.get(routeHandler, 'uiView') === 'ArticleDetailContentImages'),
              })}
            >
              {React.Children.map(this.props.children, (child) => {
                if (routeHandler) {
                  return (
                    <CSSTransition classNames="pageTransition" timeout={{ exit: 200, enter: 500 }}>
                      <ErrorBoundary routeHandler={routeHandler}>
                        {this.state.spMode ? (
                          <React.Fragment>
                            {_.get(this.context, 'routeHandler.uiView') === 'specialdetail' && (
                              <div className="special-mv-block">
                                <BackgroundImage
                                  className="artwork special-img"
                                  url={featureDetailImage}
                                  altText={routeHandler.params.tagName}
                                />
                              </div>
                            )}
                            {!isEachCompany && (
                              <EmergencyContents
                                urgentNewsLink={this.urgentNewsLink}
                                invisibleAd={_.get(this.state, 'invisibleAd')}
                              />
                            )}
                            <div
                              className={classnames('contentsWrapper', {
                                spArticleDetailContentsWrapper:
                                  _.get(this.state, 'spMode') && _.get(routeHandler, 'uiView') === 'articleDetail',
                              })}
                              key="sp_main_contents"
                            >
                              {isArticleDetail && (
                                <div className="bread-crumb-container">
                                  <HeaderBreadcrumb />
                                </div>
                              )}
                              {/*
                                [ NOTE ]
                                n24-webからの取り込みであるが不要・変更想定のためコメントアウト
                                {this.getCategoryMainTitle(uiView, categoryName)}
                              */}
                              {React.cloneElement(
                                child,
                                Object.assign(
                                  {
                                    key: routeHandler.path,
                                    invisibleAd: _.get(this.state, 'invisibleAd', false)
                                  },
                                  routeHandler.params,
                                ),
                              )}
                            </div>
                            {/* TRENTA / 各社UI TOPページ SP 2nd 広告 */}
                            {
                              _.includes(allTopPagesList, uiView) &&
                              !this.state.invisibleAd && (
                                <div className="big-ad-wrapper publicity-contents" key="sp_2nd_rec">
                                  <div
                                    id={`div-gpt-${convertAdTagUiViewName(this.context)}_sp_2nd_rec`}
                                    key={`div-gpt-trenta_sp_2nd_rec${_.get(this, 'context.routeHandler.uiView')}`}
                                  ></div>
                                </div>
                              )
                            }
                            <div
                              className={classnames('module-wrapper', {
                                home: _.get(this.context, 'routeHandler.uiView') === 'home',
                                companyHome: _.get(this.context, 'routeHandler.uiView') === 'companyHome',
                                special: _.get(routeHandler, 'uiView') === 'special',
                                articleDetail: _.get(this.context, 'routeHandler.uiView') === 'articleDetail',
                                live: _.get(this.context, 'routeHandler.uiView') === 'live',
                              })}
                            >
                              {/* TRENTA / 各社UI TOPページ SP 1st 広告 */}
                              {
                              _.includes(['articles', 'articleDetail'], uiView) &&
                                !this.state.invisibleAd && (
                                  <div className="big-ad-wrapper publicity-contents" key="sp_1st_rec">
                                    <div
                                      id={`div-gpt-${convertAdTagUiViewName(this.context)}_sp_1st_rec`}
                                      key={`div-gpt-trenta_sp_1st_rec_${_.get(routeHandler, 'uiView')}`}
                                    />
                                  </div>
                                )
                              }
                              {/* トップの注目の記事 */}
                              {_.get(this.context, 'routeHandler.uiView') === 'home' && !_.isEmpty(this.context) && (
                                <FeatureArticles getAttention={this.getAttention} />
                              )}
                              {/* トップの特集棚出し */}
                              {(_.get(this.context, 'routeHandler.uiView') === 'companyHome' ||
                                _.get(this.context, 'routeHandler.uiView') === 'home') && (
                                <React.Fragment>
                                  {_.get(this.context, 'routeHandler.isEachCompany') &&
                                    <CompanyHomeAttentionContent getNtvHeadlineNews={this.getNtvHeadlineNews} />
                                  }
                                  {/* TRENTA / 各社UI TOPページ SP 3rd 広告 */}
                                  {
                                  _.includes(allTopPagesList, uiView) &&
                                    !this.state.invisibleAd && (
                                      <div className="big-ad-wrapper publicity-contents" key="sp_3rd_rec">
                                        <div
                                          id={`div-gpt-${convertAdTagUiViewName(this.context)}_sp_3rd_rec`}
                                          key={`div-gpt-trenta_sp_3rd_rec_${_.get(routeHandler, 'uiView')}`}
                                        />
                                      </div>
                                    )
                                  }
                                  <LazyLoadComponent>
                                    <HomeSpecialContent
                                      model={this.model}
                                      routeHandler={this.props.routeHandler}
                                      featureList={this.featureTags}
                                      company={_.get(this.context, 'routeHandler.uiView') === 'companyHome'}
                                      eachCompany={_.get(this.context, 'routeHandler.isEachCompany')}
                                      home={_.get(this.context, 'routeHandler.uiView') === 'home'}
                                    />
                                  </LazyLoadComponent>
                                </React.Fragment>
                              )}
                              {/* TRENTA / 各社UI TOPページ SP 4th 広告 */}
                              {
                              _.includes(allTopPagesList, uiView) &&
                                !this.state.invisibleAd && (
                                  <div className="big-ad-wrapper publicity-contents" key="sp_4th_rec">
                                    <div
                                      id={`div-gpt-${convertAdTagUiViewName(this.context)}_sp_4th_rec`}
                                      key={`div-gpt-trenta_sp_4th_rec_${_.get(routeHandler, 'uiView')}`}
                                    />
                                  </div>
                                )
                              }
                              {/* TOP SP版ランキング */}
                              {(_.get(this.context, 'routeHandler.uiView') === 'companyHome' ||
                                _.get(this.context, 'routeHandler.uiView') === 'home') && (
                                <React.Fragment>
                                  <RankingList
                                    categoryCode={this.categoryCode}
                                    articles={this.rankingArticles}
                                    invisibleAd={this.state.invisibleAd}
                                    company={true}
                                  />
                                </React.Fragment>
                              )}
                              {_.get(this.context, 'routeHandler.uiView') === 'articleDetail' &&
                              _.get(this.context,'routeHandler.params.categoryCode') !== "sponsored" &&
                                !_.get(this.context, 'routeHandler.listJson') && (
                                  <React.Fragment>
                                    <LiveCard
                                      item={_.get(this, 'liveLink')}
                                      articleDetail={true}
                                      routeHandler={this.context.routeHandler}
                                    />
                                  </React.Fragment>
                                )}
                              {_.get(this.context, 'routeHandler.uiView') !== 'live' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'companyHome' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'home' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'local' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'new' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'ranking' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'search' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'articleDetail' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'special' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'specialdetail' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'ArticleDetailContentImages' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'info' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'tag' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'sns' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'SemiStaticPages' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'InformationContentDetail' &&
                                _.get(this.context,'routeHandler.params.categoryCode') !== "sponsored" &&
                                !_.isEmpty(this.liveLink) && (
                                  <React.Fragment>
                                    <LiveCard item={_.get(this, 'liveLink')} routeHandler={this.context.routeHandler} />
                                  </React.Fragment>
                                )}
                              {!_.isEmpty(this.inductionLink) &&
                                _.get(this.context, 'routeHandler.uiView') !== 'live' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'companyHome' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'home' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'local' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'ArticleDetailContentImages' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'special' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'specialdetail' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'ranking' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'home' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'new' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'search' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'tag' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'sns' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'SemiStaticPages' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'info' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'InformationContentDetail' &&
                                _.get(this.context,'routeHandler.params.categoryCode') !== "sponsored" &&
                                _.map(this.inductionLink, (link, index) => {
                                  return (
                                    <TitleCard
                                      introduction={true}
                                      itemData={link}
                                      key={`introduction_card_${index}`}
                                      indexNumber={index}
                                    />
                                  );
                                })}

                              {/* TRENTA / 各社UI TOPページ SP Rec 広告 */}
                              {
                                _.includes([
                                  'ArticlesContentImages',
                                  'ArticleDetailContentImages',
                                  'ArticleDetailContentImagesEachCompany'
                                ], uiView) &&
                                !this.state.invisibleAd && (
                                  <div className="big-ad-wrapper publicity-contents" key="sp_2nd_rec">
                                    <div
                                      id={`div-gpt-${convertAdTagUiViewName(this.context)}_sp_article_rec`}
                                      key={`div-gpt-trenta_sp_article_rec${_.get(this, 'context.routeHandler.uiView')}`}
                                    ></div>
                                  </div>
                                )
                              }
                              {_.get(this.context, 'routeHandler.uiView') === 'articleDetail' &&
                                !_.get(this.context, 'routeHandler.isEachCompany', false) && (
                                  <FeatureArticles getAttention={this.getAttention} />
                                )}
                              {/* SP版全局のヘッドライン */}
                              {(_.get(this.context, 'routeHandler.uiView') === 'articleDetail' ||
                                _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImages' ||
                                _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImagesEachCompany') &&
                                _.get(this.context, 'routeHandler.isEachCompany') &&
                                !_.isEmpty(this.context) && (
                                  <React.Fragment>
                                    <CompanyHomeAttentionContent getNtvHeadlineNews={this.getNtvHeadlineNews} />
                                  </React.Fragment>
                                )}
                              {!this.state.invisibleAd &&
                                this.state.calcColumn &&
                                _.get(this.context, 'routeHandler.uiView') === 'articleDetail' &&
                                this.companyCode !== 'ntv' &&
                                !_.get(this.context, 'routeHandler.isEachCompany') && (
                                  <React.Fragment>
                                    <HeadlineListEachTrenta companyCode={this.companyCode} model={this.model} />
                                  </React.Fragment>
                                )}
                              {/* SP版 注目の記事 */}
                              {(_.get(this.context, 'routeHandler.uiView') === 'articles' ||
                                _.get(this.context, 'routeHandler.uiView') === 'ArticlesContentImages' ||
                                _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImages' ||
                                _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImagesEachCompany') &&
                                !_.get(this.context, 'routeHandler.isEachCompany') && (
                                  <React.Fragment>
                                    <FeatureArticles getAttention={this.getAttention} />
                                  </React.Fragment>
                                )}
                              {_.get(this.context, 'routeHandler.uiView') !== 'ranking' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'companyHome' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'home' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'local' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'live' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'ArticleDetailContentImages' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'ArticleDetailContentImagesEachCompany' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'new' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'tag' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'sns' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'SemiStaticPages' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'search' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'special' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'specialdetail' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'info' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'InformationContentDetail' && (
                                  <React.Fragment>
                                    {/* SP版記事詳細画面ランキング */}
                                    <RankingList
                                      categoryCode={this.categoryCode}
                                      articles={this.rankingArticles}
                                      invisibleAd={this.state.invisibleAd}
                                      company={true}
                                    />
                                  </React.Fragment>
                                )}
                              {/* 記事詳細・画像 特集ページ SP 5th 広告 */}
                              {
                                !this.state.invisibleAd && (
                                  <div className="big-ad-wrapper publicity-contents">
                                    <div
                                      id={`div-gpt-${convertAdTagUiViewName(this.context)}_sp_5th_rec`}
                                      key={`div-gpt-trenta_sp_5th_rec_${_.get(routeHandler, 'uiView')}`}
                                    />
                                  </div>
                                )
                              }
                              {!this.state.invisibleAd &&
                                this.state.calcColumn &&
                                _.get(this.context, 'routeHandler.uiView') !== 'companyHome' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'home' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'articleDetail' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'ArticleDetailContentImages' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'ArticleDetailContentImagesEachCompany' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'live' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'new' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'special' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'specialdetail' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'local' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'ranking' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'tag' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'sns' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'SemiStaticPages' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'info' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'search' &&
                                _.get(this.context, 'routeHandler.uiView') !== 'InformationContentDetail' && (
                                  <div className="contentsWrapper recommend" key="sp_recommend_contents">
                                  <RecommendList updated={this.isUpdatedAd || false} />
                                  </div>
                                )}
                              {!this.state.invisibleAd &&
                                this.state.calcColumn &&
                                (
                                  _.get(this.context, 'routeHandler.uiView') === 'companyHome' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'home' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'articleDetail' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImages' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImagesEachCompany' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'new' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'tag' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'special' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'specialdetail' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'sns' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'SemiStaticPages' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'ranking' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'live' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'info' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'InformationContentDetail' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'search' ||
                                  _.get(this.context, 'routeHandler.uiView') === 'local'
                                ) && (
                                  <div className="contentsWrapper recommend" key="sp_recommend_contents">
                                  <RecommendList updated={this.isUpdatedAd || false} company={true} />
                                  </div>
                                )}
                            </div>
                          </React.Fragment>
                        ) : (
                          // pc mode
                          <React.Fragment>
                            <div className="contentsWrapper" key="pc_wrapper">
                              {/*
                                [ NOTE ]
                                n24-webからの取り込みであるが不要・変更想定のためコメントアウト
                                {this.getCategoryMainTitle(uiView, categoryName)}
                              */}
                              {isArticleDetail && (
                                <div className="bread-crumb-container">
                                  <HeaderBreadcrumb />
                                </div>
                              )}
                              {isShowAd4PC && (
                                <div className={`publicity-contents bg-white header-publicity button-view ${adClassNameOnArticles}`} key="pc_header_banner">
                                  <div
                                    id={`div-gpt-${convertAdTagUiViewName(this.context)}_pc_bnr`}
                                    key={`div-gpt-trenta_pc_bnr_${_.get(this.context, 'routeHandler.uiView')}`}
                                  />
                                </div>
                              )}
                              <div className="pcLayoutWrapper">
                                <div
                                  className={classnames('mainContents', {
                                    home: _.get(this.context, 'routeHandler.uiView') === 'home',
                                    companyHome: _.get(this.context, 'routeHandler.uiView') === 'companyHome',
                                    articleDetailWrapper:
                                      _.get(this.context, 'routeHandler.uiView') === 'articleDetail' ||
                                      'ArticleDetailContentImages',
                                  })}
                                  key="pc_main_contents"
                                >
                                  {React.cloneElement(
                                    child,
                                    Object.assign({ key: routeHandler.path }, routeHandler.params),
                                  )}
                                  {/* TRENTA UI トップ */}
                                  {_.get(this.context, 'routeHandler.uiView') === 'home' && (
                                    <FeatureArticles getAttention={this.getAttention} />
                                  )}
                                  {/* 広告タグ (div-gpt-*) */}
                                  {
                                    _.includes(articlesPagesList, uiView) &&
                                    !this.state.invisibleAd  && (
                                      <div className="publicity-contents" key="pc_articles_banners">
                                        <div
                                          id={`div-gpt-${convertAdTagUiViewName(this.context)}_pc_1st_rec`}
                                          key={`div-gpt-trenta_pc_1st_rec_${_.get(
                                            this.props,
                                            'routeHandler.uiView',
                                          )}`}
                                        />
                                        <div
                                          id={`div-gpt-${convertAdTagUiViewName(this.context)}_pc_2nd_rec`}
                                          key={`div-gpt-trenta_pc_2nd_rec_${_.get(
                                            this.props,
                                            'routeHandler.uiView',
                                          )}`}
                                        />
                                      </div>
                                    )
                                  }
                                  {/* PC版全国のヘッドライン */}
                                  {
                                    (
                                      _.get(this.context, 'routeHandler.uiView') === 'companyHome' ||
                                      _.includes(articlesPagesList, _.get(this.context, 'routeHandler.uiView'))
                                    ) &&
                                    _.get(this.context, 'routeHandler.isEachCompany') &&
                                    !_.isEmpty(this.context) &&
                                    (<CompanyHomeAttentionContent getNtvHeadlineNews={this.getNtvHeadlineNews} />)
                                  }
                                  {(_.get(this.context, 'routeHandler.uiView') === 'companyHome' ||
                                    _.get(this.context, 'routeHandler.uiView') === 'home') && (
                                    <React.Fragment>
                                      <LazyLoadComponent>
                                        <HomeSpecialContent
                                          model={this.model}
                                          routeHandler={this.props.routeHandler}
                                          featureList={this.featureTags}
                                          company={_.get(this.context, 'routeHandler.uiView') === 'companyHome'}
                                          home={_.get(this.context, 'routeHandler.uiView') === 'home'}
                                        />
                                      </LazyLoadComponent>
                                    </React.Fragment>
                                  )}
                                  {_.includes(articlesPagesList, uiView) && (
                                    <React.Fragment>
                                      {(_.get(this.context, 'routeHandler.uiView') === 'articleDetail' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImages') &&
                                        !_.get(this.context, 'routeHandler.isEachCompany', false) && (
                                          <FeatureArticles getAttention={this.getAttention} />
                                        )}
                                      {_.get(this.context, 'routeHandler.uiView') === 'articleDetail' &&
                                        this.companyCode !== 'ntv' &&
                                        !_.get(this.context, 'routeHandler.isEachCompany') && (
                                          <React.Fragment>
                                            <HeadlineListEachTrenta companyCode={this.companyCode} model={this.model} />
                                          </React.Fragment>
                                          )}
                                    </React.Fragment>
                                  )}
                                  {
                                    !_.includes(articlesPagesList, uiView) &&
                                    !this.state.invisibleAd  && (
                                      <div className="publicity-contents" key="pc_top_banners">
                                        <div
                                          id={`div-gpt-${convertAdTagUiViewName(this.context)}_pc_3rd_rec`}
                                          key={`div-gpt-trenta_pc_3rd_rec_${_.get(
                                            this.props,
                                            'routeHandler.uiView',
                                          )}`}
                                        />
                                        <div
                                          id={`div-gpt-${convertAdTagUiViewName(this.context)}_pc_4th_rec`}
                                          key={`div-gpt-trenta_pc_4th_rec_${_.get(
                                            this.props,
                                            'routeHandler.uiView',
                                          )}`}
                                        />
                                      </div>
                                    )
                                  }
                                  <React.Fragment>
                                    {!this.state.invisibleAd &&
                                      this.state.calcColumn &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'home' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'articles' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'ArticlesContentImages' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'articleDetail' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'ArticleDetailContentImages' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'ArticleDetailContentImagesEachCompany' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'live' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'ranking' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'new' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'local' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'info' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'tag' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'sns' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'SemiStaticPages' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'search' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'special' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'specialdetail' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'companyHome' &&
                                      _.get(this.context, 'routeHandler.uiView') !== 'InformationContentDetail' && (
                                        <RecommendList updated={this.isUpdatedAd || false} />
                                      )}
                                    {!this.state.invisibleAd &&
                                      this.state.calcColumn &&
                                      (_.get(this.context, 'routeHandler.uiView') === 'home' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'local' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'articles' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'ArticlesContentImages' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'articleDetail' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImages' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImagesEachCompany' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'ranking' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'live' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'info' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'tag' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'sns' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'SemiStaticPages' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'search' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'new' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'special' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'specialdetail' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'companyHome' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'InformationContentDetail') && (
                                        <RecommendList
                                            updated={this.isUpdatedAd || false}
                                            trenta={true}
                                        />
                                      )}
                                  </React.Fragment>
                                </div>
                                {_.get(this.context, 'routeHandler.uiView') !== 'cookiePolicy' &&
                                  _.get(this.context, 'routeHandler.uiView') !== 'pim' &&
                                  _.get(this.context, 'routeHandler.uiView') !== 'gaibusoushin' &&
                                  _.get(this.context, 'routeHandler.uiView') !== 'terms' && (
                                    <div className="sideContents" key="pc_side_1">
                                      {_.get(this.context, 'routeHandler.uiView') !== 'live' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'local' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'special' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'specialdetail' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'new' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'search' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'info' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'companyHome' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'home' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'tag' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'sns' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'SemiStaticPages' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'articleDetail' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'InformationContentDetail' &&
                                        _.get(this.context,'routeHandler.params.categoryCode') !== "sponsored" &&
                                        !_.isEmpty(this.liveLink) && (
                                          <React.Fragment>
                                            <LiveCard
                                              item={_.get(this, 'liveLink')}
                                              routeHandler={this.context.routeHandler}
                                            />
                                          </React.Fragment>
                                        )}
                                      {(_.get(this.context, 'routeHandler.uiView') === 'special' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'specialdetail' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'new' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'search' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'local' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'home' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'info' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'tag' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'sns' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'SemiStaticPages' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'companyHome' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'articleDetail' ||
                                        _.get(this.context, 'routeHandler.uiView') === 'InformationContentDetail') &&
                                        _.get(this.context,'routeHandler.params.categoryCode') !== "sponsored" &&
                                        !_.get(this.context, 'routeHandler.listJson') &&
                                        !_.isEmpty(this.liveLink) && (
                                          <React.Fragment>
                                            <LiveCard
                                              item={_.get(this, 'liveLink')}
                                              routeHandler={this.context.routeHandler}
                                            />
                                          </React.Fragment>
                                        )}
                                      {!this.state.invisibleAd && (
                                        <div
                                          className="side-publicity"
                                          id={`div-gpt-${convertAdTagUiViewName(this.context)}_pc_5th_rec`}
                                          key={`div-gpt-trenta_pc_5th_rec_${_.get(routeHandler, 'uiView')}`}
                                        />
                                      )}
                                      {!_.isEmpty(this.inductionLink) &&
                                        _.get(this.context,'routeHandler.params.categoryCode') !== "sponsored" &&
                                        _.map(this.inductionLink, (link, index) => {
                                          return (
                                            <LazyLoadComponent key={`introduction-lazyload_${index}`}>
                                              <TitleCard
                                                indexNumber={index}
                                                introduction={true}
                                                itemData={link}
                                                key={`top-introduction-card_${index}`}
                                              />
                                            </LazyLoadComponent>
                                          );
                                        })}
                                      {_.get(this.context, 'routeHandler.uiView') !== 'ranking' && (
                                        <React.Fragment>
                                          <RankingList
                                            categoryCode={this.categoryCode}
                                            articles={this.rankingArticles}
                                            invisibleAd={this.state.invisibleAd}
                                            sideContent={true}
                                          />
                                        </React.Fragment>
                                      )}
                                      {_.get(this.context, 'routeHandler.uiView') === 'ranking' &&
                                        _.get(this.context, 'routeHandler.isEachCompany') && (
                                          <React.Fragment>
                                            <RankingList
                                              categoryCode={this.categoryCode}
                                              articles={this.rankingArticles}
                                              invisibleAd={this.state.invisibleAd}
                                              sideContent={true}
                                            />
                                          </React.Fragment>
                                        )}
                                      {_.get(this.context, 'routeHandler.uiView') !== 'home' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'local' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'new' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'search' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'articleDetail' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'live' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'ranking' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'special' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'specialdetail' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'ArticleDetailContentImages' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'ArticleDetailContentImagesEachCompany' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'info' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'tag' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'sns' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'SemiStaticPages' &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'companyHome' &&
                                        _.get(this.context,'routeHandler.params.categoryCode') !== "sponsored" &&
                                        _.get(this.context, 'routeHandler.uiView') !== 'InformationContentDetail' && (
                                          <React.Fragment key="not_home_wrapper_banner">
                                            <LazyLoadComponent key="banner-list-lazyload">
                                              <BannerList links={this.ntvBannerLink} />
                                            </LazyLoadComponent>
                                          </React.Fragment>
                                        )}
                                      <React.Fragment key="not_home_wrapper">
                                        {(_.get(this.context, 'routeHandler.uiView') === 'ranking' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'info' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'new' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'search' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'live' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'special' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'local' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'specialdetail' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImages' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImagesEachCompany' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'articleDetail' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'companyHome' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'home' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'tag' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'sns' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'SemiStaticPages' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'InformationContentDetail') &&
                                          _.get(this.context,'routeHandler.params.categoryCode') !== "sponsored" && (
                                          <LazyLoadComponent key="banner-list-lazyload">
                                            <BannerList links={this.ntvBannerLink} />
                                          </LazyLoadComponent>
                                        )}
                                        {
                                          !this.state.invisibleAd && (
                                          <div
                                            className="side-publicity"
                                            id={`div-gpt-${convertAdTagUiViewName(this.context)}_pc_6th_rec`}
                                            key={`div-gpt-trenta_pc_6th_rec_${_.get(routeHandler, 'uiView')}`}
                                          />
                                        )}
                                        {_.get(this.context, 'routeHandler.isEachCompany') && (
                                          // 各社UIサイドのフッター
                                          <LazyLoadComponent key="side-eachCompanyFooter-lazyload">
                                            <Footer
                                              eachCompanyFooter={this.eachCompanyFooter}
                                              isEachCompany={true}
                                              sideFooterContent={true}
                                              companyCode={_.get(this.props.routeHandler, 'params.companyCode')}
                                              footerLink={this.footerLink}
                                            />
                                          </LazyLoadComponent>
                                        )}
                                        {/* TRENTA UI　サイドフッター */}
                                        {(_.get(this.context, 'routeHandler.uiView') === 'ranking' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'articleDetail' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'live' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'sns' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'SemiStaticPages' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'tag' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'home' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'special' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'specialdetail' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'local' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'new' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'search' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'info' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImages' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'ArticleDetailContentImagesEachCompany' ||
                                          _.get(this.context, 'routeHandler.uiView') === 'InformationContentDetail') &&
                                          !_.get(this.context, 'routeHandler.isEachCompany') && (
                                            <Footer trentaSideContent={true} footerLink={this.footerLink} />
                                          )}
                                        {/* PCサイドメニュー広告 */}
                                        {!this.state.invisibleAd &&  (
                                          <div className="sticky-wrapper sticky" key="sticky-wrapper-1">
                                            <div
                                              id={`div-gpt-${convertAdTagUiViewName(this.context)}_pc_bigrec`}
                                              key={`div-gpt-trenta_pc_bigrec_${_.get(routeHandler, 'uiView')}`}
                                            />
                                          </div>
                                        )}
                                      </React.Fragment>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </ErrorBoundary>
                    </CSSTransition>
                  );
                }
                return child;
              })}
            </TransitionGroup>
            {isArticleDetail && renderMode != 'sp-guides' && this.renderHeader()}
            {renderMode != 'sp-guides' && (
              <Footer
                onClick={this.scrollToTop}
                spMode={this.state.spMode}
                invisibleAd={this.state.invisibleAd}
                footerAd={true}
                routeHandler={this.routeHandler || routeHandler}
                footerLink={this.footerLink}
                eachCompanyFooter={this.eachCompanyFooter}
              />
            )}
          </LayoutContext.Provider>
        </div>
        {typeof window !== 'undefined' && !_.isEmpty(window.fluxtag) && this._isMounted && (
          <GoogleAdManager
            articleData={_.get(this.context, 'models.articleData', {})}
            spMode={this.state.spMode}
            invisibleAd={this.state.invisibleAd}
            routeHandler={this.routeHandler || routeHandler}
          />
        )}
      </React.Fragment>
    );
  }
}

const root = htmlContext(BlueroseLayout);
root.getPaths = BlueroseLayout.getPaths;
root.getPrefetchPaths = BlueroseLayout.getPrefetchPaths;
export default root;
