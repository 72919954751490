import window from 'global/window';
import { compose, defaultProps, lifecycle, pure } from 'recompose';
import _get from 'lodash/get';
import _find from 'lodash/find';

// head上部に出力:<link rel="canonical" href="{canonical}" />
const setCanonicalLink = canonical => {
  if (!canonical) return; // canonical url propされていない・存在しない場合
  const head = document.getElementsByTagName('head')[0];
  const isExistLinkTag = !!document.querySelector("link[rel='canonical']");
  const link = isExistLinkTag ? document.querySelector("link[rel='canonical']") : document.createElement('link');

  // すでにタグが存在する場合、canonical href更新のみ
  if (isExistLinkTag) {
    link.setAttribute('href', canonical);
    return;
  }

  // 初回描画時、linkタグ(canonical)生成
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', canonical);
  head.prepend(link);
};

const ChartbeatManager = compose(
  pure,
  defaultProps({ pageTitle: '', categories: [], path: '', canonical: '', env: '' }),
  lifecycle({
    componentDidUpdate() {
      if (typeof window !== 'undefined') {
        const path = _get(this.props, 'path');
        const unviewCanonical = _get(this.props, 'unviewCanonical');
        let canonical = _get(this.props, 'canonical');
        if (!canonical && !unviewCanonical) {
          canonical = `${location.protocol}//${location.host}${path}`;
        }
        setCanonicalLink(canonical); // canonical 設定
      }
    },
    componentDidMount() {
      const chartbeatEnv = _get(this.props, 'env') === 'production' ? '' : 'stg.';
      const scripts = document.getElementsByTagName('script');
      const pageTitle = _get(this.props, 'pageTitle');
      const categories = _get(this.props, 'categories');
      const path = _get(this.props, 'path');
      const unviewCanonical = _get(this.props, 'unviewCanonical');
      let canonical = _get(this.props, 'canonical');

      if (!canonical && !unviewCanonical) {
        canonical = `${location.protocol}//${location.host}${path}`;
      }
      setCanonicalLink(canonical); // canonical 設定

      // ２回目以降のページ遷移
      if (_find(scripts, { src: 'https://static.chartbeat.com/js/chartbeat.js' })) {
        const _sf_async_config = window._sf_async_config;
        if (pageTitle) _sf_async_config.title = pageTitle;
        if (categories) {
          _sf_async_config.sections = categories.join(',');
        }
        if (path) {
          _sf_async_config.path = path;
        }
        if (window && window.pSUPERFLY) {
          window.pSUPERFLY.virtualPage({
            sections: _sf_async_config.sections,
            path: _sf_async_config.path,
            title: _sf_async_config.title,
          });
        }
      // 初回レンダーリング
      } else {
        const _sf_async_config = window._sf_async_config = (window._sf_async_config || {});
        _sf_async_config.uid = 66272;
        _sf_async_config.domain = `${chartbeatEnv}news.ntv.co.jp`;
        _sf_async_config.flickerControl = false;
        _sf_async_config.useCanonical = true;
        _sf_async_config.useCanonicalDomain = true;
        if (pageTitle) _sf_async_config.title = pageTitle;
        if (categories) {
          _sf_async_config.sections = categories.join(',');
        }

        function loadChartbeat() {
          const chartBeat = document.createElement('script');
          const chartBeatMab = document.createElement('script');
          const firstScript = document.getElementsByTagName('script')[0];
          chartBeat.type = 'text/javascript';
          chartBeat.async = true;
          chartBeat.src = 'https://static.chartbeat.com/js/chartbeat.js';

          chartBeatMab.type = 'text/javascript';
          chartBeatMab.async = true;
          chartBeatMab.src = 'https://static.chartbeat.com/js/chartbeat_mab.js';

          firstScript.parentNode.insertBefore(chartBeat, firstScript);
          firstScript.parentNode.insertBefore(chartBeatMab, firstScript);
        }
        loadChartbeat();
      }
    },
  }),
)(() => null);
export default ChartbeatManager;
