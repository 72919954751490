import window from 'global/window';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import * as browserEvents from '../../../sketch-platform/utils/browserEvents';
import { withLayoutContext } from '../../common/context/LayoutContext';
import ScrollMenu from '../../bluerose/components/browse/ScrollMenu';
import MainViewLink from './MainViewLink';
import routes from '../../common/routes';
import BannerList from '../../bluerose/components/browse/BannerList';
import categoryName2Code from '../../../common/categoryName2Code';
import LiveCard from '../../bluerose/components/browse/LiveCard';
import devConfigs from '../../../config/environments/production';
import HeaderMainMenu from './HeaderMainMenu';
import Footer from './Footer';
import getBroadcasterData from '../../../common/getBroadcasterData';
import categoryCode2Name from '../../../common/categoryCode2Name';
import HeaderBreadcrumb from './HeaderBreadcrumb';
import EmergencyContents from './EmergencyContents';

class Header extends Component {
  static propTypes = {
    item: PropTypes.object,
    headerMenu: PropTypes.array,
    headerLogo: PropTypes.bool,
    eachCompanyHeader: PropTypes.object,
  };

  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object,
    staticJsonApp: PropTypes.object,
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      menuOpen: false,
      searchWindow: false,
      term: _.get(context, 'routeHandler.query.q', ''),
      spMenuPosition: 0,
      headerClassName: '',
      offSetHeight: 0,
    };
    this._isMounted = false;
    this.searchInputRef = React.createRef();
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.checkMediaQuery = this.checkMediaQuery.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchOpen = this.handleSearchOpen.bind(this);
    this.globalNavigation = this.globalNavigation.bind(this);
    this.SearchBlock = this.SearchBlock.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearchTerm = this.handleSearchTerm.bind(this);
    this.changeHistory = this.changeHistory.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.headerMenu = _.get(props, 'headerMenu');
    this.headerHtml = _.get(props, 'eachCompanyHeader.header.html');
    this.bannerLinks = _.get(props, 'bannerLinks');
    this.model = props.pathEvaluator || props.model.pathEvaluator;
    this.totalCount = 0;
    if(_.get(context.routeHandler, 'uiView') === "articleDetail"){
      const isTrentaUiType = !_.get(props.routeHandler, 'isEachCompany', false);
      this.article = this.model.getSync(['article', props.routeHandler.params.id, isTrentaUiType]);
    }
    this.categoryName = categoryCode2Name(props.routeHandler.params.categoryCode, context.models.config.data);
    this.broadCastData = getBroadcasterData(_.get(this.props, 'routeHandler.params.companyCode', 'n24'));
    this.headerRef = React.createRef();
    this.lastScrollY = 0;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.headerRef.current) {
      this.setState({ offSetHeight: this.headerRef.current.offsetHeight });
    }
    window.addEventListener('scroll', this.handleScroll);
    this.checkMediaQuery();

    browserEvents.addEventListener('resize', this.checkMediaQuery);
  }

  componentDidUpdate() {
    this.hoverReset();
  }

  componentWillUnmount() {
    this._isMounted = false;
    browserEvents.removeEventListener('resize', this.checkMediaQuery);
    window.removeEventListener('scroll', this.handleScroll);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.term != state.term) {
      return {
        term: props.term,
      };
    }
    return null;
  }

  checkMediaQuery() {
    const browserInfo = this.context.getModelData('browserInfo');
    if (typeof window === 'undefined') return;
    // const spMode = window.matchMedia(`(max-width: 859px)`).matches;
    // console.log(spMode);
    // if (this._isMounted) {
    //   this.setState({ spMode });
    // } else {
    //   Object.assign(this.state, { spMode });
    // }
    if (!(browserInfo.isIOS || browserInfo.isAndroid)) {
      this.setState({ searchWindow: false });
    }
  }

  handleMenuClick() {
    const headerHight = document.querySelector('.main-header-contents').offsetHeight
    this.setState({ spMenuPosition: headerHight });
    if (this.state.menuOpen) {
      this.setState({ menuOpen: false });
    } else {
      this.setState({ menuOpen: true, headerClassName: '' });
      this.setState({ searchWindow: false });
    }
  }

  handleSearchClick(e) {
    e.preventDefault();
    this.handleSearchExec();
    this.handleSearchOpen();
  }

  handleSearchTerm(e) {
    if (this.onChangeTermTimeId) {
      clearTimeout(this.onChangeTermTimeId);
      delete this.onChangeTermTimeId;
    }
    this.onChangeTermTimeId = setTimeout(() => {
      clearTimeout(this.onChangeTermTimeId);
      delete this.onChangeTermTimeId;
    }, 100);
  }

  getHeaderClassName() {
    const currentScrollY = window.scrollY;
    const { offSetHeight, headerClassName } = this.state;
    if (currentScrollY <= this.lastScrollY) {
      return 'DownMove';
    }
    if ((currentScrollY > offSetHeight) && (headerClassName === 'DownMove' || headerClassName === 'UpMove')) {
      return 'UpMove';
    }
    return '';
  }

  handleScroll() {
    const { searchWindow, menuOpen } = this.state;
    if (searchWindow || menuOpen) return;
    const currentScrollY = window.scrollY;
    const newHeaderClassName = this.getHeaderClassName();
    this.lastScrollY = window.scrollY;
    if (this.state.headerClassName === newHeaderClassName) return;
    this.setState({
      headerClassName: newHeaderClassName,
    });
  };


  changeHistory(conditions = {}) {
    const { routeHandler, history } = this.context;
    const { term } = Object.assign({}, this.state, conditions);

    if (this.searchInputRef && this.searchInputRef.current) {
      this.searchInputRef.current.value = '';
    }

    const query = {
      q: term || '',
    };

    const category = document.querySelector('.search-dropdown');
    const startCalender = document.querySelectorAll('.dateStart');
    const endCalender = document.querySelectorAll('.dateEnd');
    if (_.get(category, 'innerText')) {
      const code = categoryName2Code(category.innerText, _.get(this.context, 'models.config.data')); // 検索はTRENTAのみのため、providerCode割愛
      if (code) query.sc = code;
    }
    if (_.get(startCalender, 'flatpickr')) {
      const startCalenderInstance = startCalender.flatpickr().element;
      if (_.get(startCalenderInstance, 'value')) {
        query.f = startCalenderInstance.value;
      }
    }
    if (_.get(endCalender, 'flatpickr')) {
      const endCalenderInstance = endCalender.flatpickr().element;
      if (_.get(endCalenderInstance, 'value')) {
        query.t = endCalenderInstance.value;
      }
    }

    const url = routes.search.makePath({}, query);
    window.location.href = url;
  }

  handleCategoryChange(e, value) {
    e.preventDefault();
    this.setState({ category: value });
  }

  handleSearchExec() {
    const term = _.get(this.searchInputRef, 'current.value');
    this.handleSearchOpen();
    this.changeHistory({ term });
  }

  handleKeyDown(e) {
    if (e.which === 13) {
      this.handleSearchExec();
    }
  }

  handleSearchOpen() {
    if (this.state.searchWindow) {
      this.setState({ searchWindow: false });
    } else {
      this.setState({ searchWindow: true, headerClassName: '' });
      this.setState({ menuOpen: false });
    }
  }

  SearchBlock() {
    return (
      <React.Fragment>
        <div className="search-click">
          <div className="search-input">
            <div className="search-input-text">
              <input
                ref={this.searchInputRef}
                type="text"
                placeholder="キーワードを入力"
                onChange={this.handleSearchTerm}
                onKeyDown={this.handleKeyDown}
                maxLength={30}
              />
            </div>
            <span className="btn btn-fill black-color btn-small-s" onClick={this.handleSearchClick}>
              検索
            </span>
          </div>
        </div>
        <div className="spMenu-bg" onClick={this.handleSearchOpen} />
      </React.Fragment>
    );
  }

  globalNavigation() {
    // TODO: currentフラグの設定
    return (
      <div className="global-navigation">
        <div className="global-navigation-inner">
          <a className="global-navigation-item current">
            <i className="fa-hot" />
            <span>ヘッドライン</span>
          </a>
          <a className="global-navigation-item">
            <i className="fa-latest" />
            <span>新着</span>
          </a>
          <a className="global-navigation-item">
            <i className="fa-pin" />
            <span>地域</span>
          </a>
          <a className="global-navigation-item">
            <i className="fa-live" />
            <span>ライブ</span>
          </a>
        </div>
      </div>
    );
  }

  getRootPath = function (models, options, props) {
    const filter = {
      category: _.get(props, 'routeHandler.query.sc'),
      term: _.get(props, 'routeHandler.query.q'),
      from: _.get(props, 'routeHandler.query.f'),
      to: _.get(props, 'routeHandler.query.t'),
    };
    return [
      'article',
      'search',
      'byTerm',
      filter.term || '',
      filter.category || '',
      filter.from || '',
      filter.to || '',
    ];
  };

  hoverReset() {
    const browserInfo = this.context.getModelData('browserInfo');
    if (typeof window === 'object') {
      let targetList = document.getElementsByClassName('spMenu-h1-item');
      if (this.context.spMode || browserInfo.isIOS || browserInfo.isAndroid) {
        for (let i = 0; i < targetList.length; i++) {
          const targetItem = targetList[i];
          targetItem.style.opacity = 1;
        }
      }
    }
  }

  render() {
    const { headerMenu, trentaHeaderMenu, trentaCategoryMenu } = this.props;
    const isEachCompany = _.get(this.props, 'routeHandler.isEachCompany', false);
    const uiView = _.get(this.props.routeHandler, 'uiView');
    let titleInH1;
    const path = _.get(this.context, 'routeHandler.url');
    if (_.includes(path, 'search')) {
      const rootPath = this.getRootPath(this.context.models, null, this.props);
      if (this.model) {
        this.totalCount = this.model.getSync(rootPath.concat('count')) || 0;
      } else {
        this.totalCount = 0;
      }
    }
    if (path) {
      const headerMenuPath = _.find(headerMenu, { link_url: path });
      const splitPath = path.split('/');
      const lastPath = _.last(splitPath);
      const { code2name } = devConfigs.categoryTags;

      if (headerMenuPath) {
        titleInH1 = headerMenuPath.title;
        if (titleInH1 === 'トップ') {
          titleInH1 = '日テレNEWS NNN';
        }
      } else {
        if (_.includes(path, 'new') || _.includes(path, 'ranking')) {
          if (_.includes(path, 'new') && lastPath === 'all') {
            titleInH1 = '最新のニュース';
          } else if (_.includes(path, 'ranking') && lastPath === 'all') {
            titleInH1 = 'ランキング';
          } else {
            titleInH1 = _.get(code2name, lastPath);
          }
        }
        if (lastPath === 'live') {
          titleInH1 = '24時間ライブ配信中';
        }
        if (_.includes(lastPath, 'search')) {
          const searchQuery = _.get(this.context, 'routeHandler.query.q', '');
          titleInH1 = `"${searchQuery}"の検索結果${this.totalCount}件`;
        }
        if (_.includes(path, 'feature')) {
          const decodedUrl = decodeURI(path);
          const splitFeaturePath = decodedUrl.split('/');
          const featureName = _.last(splitFeaturePath);
          titleInH1 = featureName;
        }
        if (_.includes(path, 'tag')) {
          titleInH1 = `#${decodeURI(lastPath)}`;
        }
      }
    }

    const img_src = this.context.models.config.data.logoPath;
    const defaultEachCompanyHeader = (
      <div className='default-header-wrapper'>
        <header className='default-header'>
          <MainViewLink to={routes.companyHome} params={{companyCode: _.get(this.props.routeHandler, 'params.companyCode')}}>
            <img
              className='default-header-image'
              src={getBroadcasterData(_.get(this.props.routeHandler, 'params.companyCode')).img_src.e_m}
              alt={getBroadcasterData(_.get(this.props.routeHandler, 'params.companyCode')).label}
            />
          </MainViewLink>
        </header>
      </div>
    )

    // 各社UIの場合は各社UIのjsonの内容を描画する
    if (isEachCompany) {
      return (
        <div className='main-header'>
          {/* headerのjsonのhtmlタグが取得できない場合、デフォルトヘッダーを表示する。 */}
          {_.isEmpty(this.headerHtml) ? defaultEachCompanyHeader : <div dangerouslySetInnerHTML={{ __html: this.headerHtml }} />}
          {(uiView === 'articleDetail' || uiView === 'ArticleDetailContentImages') && !isEachCompany && _.get(this.props, 'spMode') && (
            <EmergencyContents
              urgentNewsLink={_.get(this.props, 'urgentNewsLink')}
              invisibleAd={_.get(this.props, 'invisibleAd')}
            />
          )}
        </div>
      );
    }

    return (
      <>
        {this.context.spMode && this.state.menuOpen && <div style={{marginTop:this.state.spMenuPosition}}/>}
        <header className={`main-header ${this.state.headerClassName}`} ref={this.headerRef}>
          {this.context.spMode && (
            <SpHeaderMenu
              onClick={this.handleMenuClick}
              open={this.state.menuOpen}
              headerMenu={this.headerMenu}
              trentaCategoryMenu={trentaCategoryMenu}
              bannerLinks={this.bannerLinks}
              topPotision={this.state.spMenuPosition}
              categoryTag={_.get(this.props, 'categoryTag')}
              unmountMenu={this.handleMenuClick}
              key={'sp-HeaderMenu'}
              item={this.props.item}
              footerLink={this.props.footerLink}
            />
          )}
          {this.context.spMode && this.state.searchWindow ? this.SearchBlock() : null}
          <div
            className={classnames('main-header-contents', {
              'header-fixed':
                (this.context.spMode && this.state.menuOpen) || (this.context.spMode && this.state.searchWindow),
            })}
          >
            <div className={classnames('main-header-contents-inner', { 'spMenu-header': this.context.spMode })}>
              <div className="main-header-contents-left">
                <div title={titleInH1} className={classnames('h_tag', { 'spMenu-h1': this.context.spMode })}>
                  <MainViewLink to={routes.home} className={classnames({ 'spMenu-h1-item': this.context.spMode })}>
                    <h1>
                      <img className="header-logo" src={img_src} alt="日テレNEWS NNN｜日本テレビ系NNN30局のニュースサイト" />
                    </h1>
                  </MainViewLink>
                </div>
                {!_.isEmpty(_.get(this.props, 'urgentNewsLink')) && !_.get(this.props, 'spMode') && (
                  <EmergencyContents
                    urgentNewsLink={_.get(this.props, 'urgentNewsLink')}
                    invisibleAd={_.get(this.props, 'invisibleAd')}
                  />
                )}
              </div>
              <div className="main-header-contents-right">
                {this.context.spMode ? (
                  <React.Fragment>
                    <div className="search-input header-search mobile" onClick={this.handleSearchOpen}>
                      <React.Fragment>
                        <i className="fa-search search-icon" />
                        <span>検索</span>
                      </React.Fragment>
                    </div>
                    <div className="spMenu-icon" onClick={this.handleMenuClick}>
                      <React.Fragment>
                        <i className="fa-menu" />
                        <span>メニュー</span>
                      </React.Fragment>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="search-input header-search">
                    <input
                      ref={this.searchInputRef}
                      type="text"
                      placeholder="キーワードを入力"
                      onChange={this.handleSearchTerm}
                      onKeyDown={this.handleKeyDown}
                      maxLength={30}
                    />
                    <i className="fa-search search-icon" onClick={this.handleSearchClick} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <HeaderMainMenu menuItem={trentaHeaderMenu} />
          {uiView !== 'info' &&
            uiView !== 'InformationContentDetail' &&
            uiView !== 'SemiStaticPages' &&
            !this.props.spMode &&
            this.context.routeHandler.uiViewLabel === 'トップ' && (
              <ScrollMenu headerMenu={true} list={trentaCategoryMenu} categoryTag={_.get(this.props, 'categoryTag')} />
            )}
        </header>
      </>
    );
  }
}

class SpHeaderMenu extends Component {
  static propTypes = {
    open: PropTypes.bool,
    trentaCategoryMenu: PropTypes.array,
    bannerLinks: PropTypes.array,
    item: PropTypes.object,
    topPotision: PropTypes.number,
  };
  static contextTypes = {
    getModelData: PropTypes.func,
    routeHandler: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this._isMounted = false;

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      open: false,
    };
  }

  handleClick() {
    if (_.get(this.props, 'unmountMenu')) {
      this.props.unmountMenu();
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let headerMenus;
    const uiView = _.get(this.context.routeHandler, 'uiView');
    const categoryTag = _.get(this.props, 'categoryTag');
    headerMenus = _.compact(
      _.map(this.props.trentaCategoryMenu || [], (itemData, index) => {
        if (!itemData || !itemData.title) return;
        return (
          <div className="item-category-item" key={`sp-header-menu-${index}`}>
            <MainViewLink
              onClick={this.handleClick}
              href={itemData.link_url}
              className={classnames('item-category-item-link', {
                active:
                  this.context.routeHandler.url === encodeURI(itemData.link_url) ||
                  ((uiView === 'special' || uiView === 'specialdetail') && itemData.link_url.match(/^\/feature/)) ||
                  ((uiView === 'local' || uiView === 'localDetail') && itemData.link_url.match(/^\/nnn/)) ||
                  ((uiView === 'articles' ||
                    uiView === 'ArticlesContentImages' ||
                    uiView === 'articleDetail' ||
                    uiView === 'ArticleDetailContentImages') &&
                    categoryTag === itemData.title),
              })}
            >
              {itemData.title}
            </MainViewLink>
          </div>
        );
      }),
    );

    return (
      <React.Fragment>
        {this.props.open ? <div className="spMenu-bg" onClick={this.props.onClick} /> : null}
        <div className={classnames('spMenu', { open: this.props.open })} style={{marginTop:this.props.topPotision}}>
          <div className="spMenu-content" id="top-menu-id">
            {_.get(this.context, 'routeHandler.uiView') !== 'live' &&
              !_.get(this.context, 'routeHandler.listJson') &&
              !_.isEmpty(this.props.item) && (
                <div className="spMenu-content-item spMenu-player" onClick={this.handleClick}>
                  <LiveCard item={[this.props.item]} />
                </div>
              )}
            <div className="spMenu-content-item item-category">
              <p className="item-category-title">カテゴリー</p>
              <div className="item-category-content">{headerMenus}</div>
            </div>
            <div className="spMenu-content-item">
              <Footer trentaSideContent onlyIcon spMenu footerLink={this.props.footerLink} />
            </div>
            <div className="spMenu-content-item">
              <BannerList links={this.props.bannerLinks} />
            </div>
            <div className="spMenu-content-item">
              <Footer trentaSideContent onlyLink spMenu footerLink={this.props.footerLink} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const root = withLayoutContext(Header);
root.getPaths = Header.getPaths;
root.getRootPath = Header.getRootPath;
root.getPrefetchPaths = Header.getPrefetchPaths;
export default root;
